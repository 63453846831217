import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  treeName: "",
  milestone: false,
  useroptin: false,
  countdown: false,
  showModalWelcome: false,
  showModalSuccess: false,
  showModalMilestone: false,
  showModalOptin: false,
  showModalComplete: false,
  showPlantedTrees: "",
  numPlantedTrees: "",
  numPlantedToday: 0,
  userData: {},
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    treeNameReducer: (state, action) => {
      state.treeName = action.payload;
    },

    setIsMilestone: (state, action) => {
      state.milestone = action.payload;
    },

    setIsUserOptin: (state, action) => {
      state.useroptin = action.payload;
    },

    setIsCountdown: (state, action) => {
      state.countdown = action.payload;
    },

    setModalOpenWelcome: (state, action) => {
      state.showModalWelcome = action.payload;
    },
    setModalOpenSuccess: (state, action) => {
      state.showModalSuccess = action.payload;
    },
    setModalOpenMilestone: (state, action) => {
      state.showModalMilestone = action.payload;
    },
    setModalOpenOptin: (state, action) => {
      state.showModalOptin = action.payload;
    },
    setModalOpenComplete: (state, action) => {
      state.showModalComplete = action.payload;
    },
    setPlantedTrees: (state, action) => {
      state.showPlantedTrees = action.payload;
    },
    setNumPlantedTrees: (state, action) => {
      state.numPlantedTrees = action.payload;
    },
    setNumPlantedToday: (state, action) => {
      state.numPlantedToday = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const {
  treeNameReducer,
  setIsMilestone,
  setIsUserOptin,
  setIsCountdown,
  setModalOpenWelcome,
  setModalOpenSuccess,
  setModalOpenMilestone,
  setModalOpenOptin,
  setModalOpenComplete,
  setPlantedTrees,
  setNumPlantedTrees,
  setNumPlantedToday,
  setUserData,
} = gameSlice.actions;

export default gameSlice.reducer;
