import { useEffect } from "react";
import { treeAnimationJablana } from "../../utils/treeAnimation";

const JablanaSVG = () => {
  useEffect(() => {
    treeAnimationJablana();
  }, []);

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="drevo-jablana"
        viewBox="0 0 510 665">
        <defs>
          <filter id="glow">
            <feGaussianBlur stdDeviation="15.5" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g id="drevo">
          <path
            filter="url(#glow)"
            d="M48.47,104.82c20-30.24,54.67-55.18,86.68-64.89s69.24-7.49,102-8.45c29.64-.86,59,2.31,88.52,3.79C418.85,39.94,501,107.48,492.21,225c-4.21,56.06-40.93,94-81.58,118.6-39.85,24.14-92.2,19.89-135.79,19.89-50.07,0-96.56-7.52-144.3-24.23-27.23-9.53-56.73-17.56-79.75-38.67-20.5-18.81-41.13-43.27-42.32-74.87C7.64,203.71,13.68,157.3,48.47,104.82Z"
            fill="#fde085"
          />
          <path
            d="M351.71,349.48c-4.66,15.19-7.6,23.26-22.95,30.58-16.38,7.8-34.76,8.69-53.16,9.13-1-25.87-1.9-43.63-2.45-47.6-1.68-12.16-5.48,4.24-21.31,4l-21.26-2.31c1.94,28.8-.42,58.27-2.77,87.48-.62-.12-1.24-.25-1.87-.34-8.09-1.22-13.7-2.08-20.29-6.2-3.62-2.27-7.85-5.07-12-8,0-20.58-4.91-47.2,7.83-63.45-7-4.46-11.78-5.27-16.61-5.42-3.84,12.74-1.17,29.63-1.17,43,0,5.85-1,12.46-.45,18.14-1.46-1.17-2.83-2.31-4.06-3.39-10.69-9.43-17.43-21-17.73-35.27C161,350,169.34,328.6,147,337.14c0,21.45-1.18,40.9,8.12,58.55,7,13.38,15,19,27.4,28.65,10.76,8.32,27.72,18.61,44,22.68-.65,8.53-1.22,17-1.62,25.45C222.25,529.31,222,586,221.16,642.8c0,1.47-.18,4.05-.37,7.29,20.64,4.47,42.59,6.33,62.77,1-1.56-69.6-4.64-172.7-7.15-240.68,25.59-.6,49.9-3.81,70.9-20.74a46,46,0,0,0,16.56-28.47C366,349.14,363.23,348.91,351.71,349.48Z"
            fill="#887c67"
          />
          <g>
            <g>
              <path
                d="M48.47,104.82c20-30.24,54.67-55.18,86.68-64.89s69.24-7.49,102-8.45c29.64-.86,59,2.31,88.52,3.79C418.85,39.94,501,107.48,492.21,225c-4.21,56.06-40.93,94-81.58,118.6-39.85,24.14-92.2,19.89-135.79,19.89-50.07,0-96.56-7.52-144.3-24.23-27.23-9.53-56.73-17.56-79.75-38.67-20.5-18.81-41.13-43.27-42.32-74.87C7.64,203.71,13.68,157.3,48.47,104.82Z"
                fill="#718369"
              />
              <g>
                <path
                  d="M195.61,186.21a3.59,3.59,0,0,1-3.59-3.52,157.53,157.53,0,0,1,.84-16.83c.45-5.23.88-10.17.83-15.2a3.6,3.6,0,1,1,7.19-.08c.06,5.38-.41,10.73-.86,15.9a153.47,153.47,0,0,0-.81,16.05,3.61,3.61,0,0,1-3.52,3.68Z"
                  fill="#8da83d"
                />
                <path
                  d="M152.26,149.37h0a3.6,3.6,0,0,1-3.58-3.61c0-5.32.23-10.73.45-15.95.29-6.85.59-13.93.38-20.77a3.6,3.6,0,0,1,3.49-3.7,3.74,3.74,0,0,1,3.7,3.49c.21,7.09-.09,14.31-.38,21.28-.22,5.15-.44,10.48-.45,15.67A3.61,3.61,0,0,1,152.26,149.37Z"
                  fill="#8da83d"
                />
                <path
                  d="M171.44,206.56a3.58,3.58,0,0,1-3.21-2c-3.59-7.11-3-16.92-2.42-25.56.19-2.9.36-5.65.36-8.05a3.6,3.6,0,0,1,3.6-3.6h0a3.59,3.59,0,0,1,3.59,3.6c0,2.64-.18,5.49-.37,8.5-.49,7.74-1.05,16.5,1.66,21.87a3.6,3.6,0,0,1-1.6,4.83A3.56,3.56,0,0,1,171.44,206.56Z"
                  fill="#8da83d"
                />
                <path
                  d="M254.81,187.17h-.13a3.6,3.6,0,0,1-3.47-3.72c.31-8.75-1.34-24.59-4-32.68a3.6,3.6,0,0,1,6.84-2.24c3,9.05,4.66,25.8,4.34,35.17A3.59,3.59,0,0,1,254.81,187.17Z"
                  fill="#8da83d"
                />
                <path
                  d="M299,128a3.62,3.62,0,0,1-3.46-2.58,78.13,78.13,0,0,1-2.3-15c-.28-3.22-.57-6.56-1.12-9.64a3.6,3.6,0,1,1,7.08-1.26c.6,3.39.91,6.89,1.21,10.28.43,4.87.83,9.47,2,13.57A3.61,3.61,0,0,1,300,127.9,3.57,3.57,0,0,1,299,128Z"
                  fill="#8da83d"
                />
                <path
                  d="M326.51,176.51a3.6,3.6,0,0,1-3.6-3.59q0-4.08,0-8.18c0-10.6.07-21.57-.84-32.24a3.6,3.6,0,0,1,7.17-.6c.93,11,.89,22.1.86,32.86,0,2.72,0,5.43,0,8.15a3.59,3.59,0,0,1-3.58,3.6Z"
                  fill="#8da83d"
                />
                <path
                  d="M307.33,191.05a3.59,3.59,0,0,1-3.59-3.59V158.37a3.6,3.6,0,0,1,7.19,0v29.09A3.6,3.6,0,0,1,307.33,191.05Z"
                  fill="#8da83d"
                />
                <path
                  d="M279.82,157.12a3.6,3.6,0,0,1-3.58-3.2c-.25-2.34-.67-4.67-1.11-7.13-.85-4.76-1.73-9.67-1.4-14.82a3.6,3.6,0,0,1,7.18.46c-.27,4.27.49,8.56,1.3,13.1.45,2.47.9,5,1.19,7.61a3.6,3.6,0,0,1-3.19,4A2.55,2.55,0,0,1,279.82,157.12Z"
                  fill="#8da83d"
                />
                <path
                  d="M279.82,226a3.6,3.6,0,0,1-3.6-3.59V192.3a3.6,3.6,0,0,1,7.2,0v30.06A3.6,3.6,0,0,1,279.82,226Z"
                  fill="#8da83d"
                />
                <path
                  d="M320.67,251.16a3.6,3.6,0,0,1-3.59-3.6v-25.2a3.6,3.6,0,1,1,7.19,0v25.2A3.6,3.6,0,0,1,320.67,251.16Z"
                  fill="#8da83d"
                />
                <path
                  d="M349,209.47a3.58,3.58,0,0,1-2.6-1.11c-1.75-1.83-1.9-4.81-1.84-11.68v-2.42a3.59,3.59,0,0,1,3.58-3.61h0a3.59,3.59,0,0,1,3.59,3.58c0,.78,0,1.64,0,2.51a48.59,48.59,0,0,0,.25,7.16,3.59,3.59,0,0,1-3,5.57Zm2.6-6.08h0Z"
                  fill="#8da83d"
                />
                <path
                  d="M363.19,148.4a3.6,3.6,0,0,1-3.59-3.6V129.29a3.6,3.6,0,0,1,7.19,0V144.8A3.6,3.6,0,0,1,363.19,148.4Z"
                  fill="#8da83d"
                />
                <path
                  d="M335.68,99.93a3.61,3.61,0,0,1-3.45-2.56c-.3-1-.62-2-1-3-1.5-4.61-3.06-9.38-2.51-14.86a3.59,3.59,0,1,1,7.15.72c-.39,4,.86,7.82,2.2,11.91.34,1.06.69,2.12,1,3.18a3.6,3.6,0,0,1-2.42,4.48A3.67,3.67,0,0,1,335.68,99.93Z"
                  fill="#8da83d"
                />
                <path
                  d="M349.85,160a3.59,3.59,0,0,1-3.59-3.59v-9.7a3.6,3.6,0,0,1,7.19,0v9.7A3.6,3.6,0,0,1,349.85,160Z"
                  fill="#8da83d"
                />
                <path
                  d="M388.2,117.38a3.6,3.6,0,0,1-3.54-3,95.59,95.59,0,0,1-.91-17.3l0-3.67a3.6,3.6,0,0,1,7.19,0l0,3.73c-.05,5.57-.1,10.83.8,16a3.6,3.6,0,0,1-2.93,4.16A4.44,4.44,0,0,1,388.2,117.38Z"
                  fill="#8da83d"
                />
                <path
                  d="M418.22,160h0a3.6,3.6,0,0,1-3.58-3.61c0-5-.41-10.22-.84-15.23a152.37,152.37,0,0,1-.82-17.83,3.64,3.64,0,0,1,3.7-3.48,3.6,3.6,0,0,1,3.49,3.71,145.08,145.08,0,0,0,.8,17c.44,5.17.89,10.52.86,15.88A3.58,3.58,0,0,1,418.22,160Z"
                  fill="#8da83d"
                />
                <path
                  d="M397.38,193a3.6,3.6,0,0,1-3.6-3.6V155.47a3.6,3.6,0,1,1,7.19,0v33.92A3.6,3.6,0,0,1,397.38,193Z"
                  fill="#8da83d"
                />
                <path
                  d="M375.7,193a3.59,3.59,0,0,1-3.35-2.29,32.6,32.6,0,0,1-1.89-15.28,3.59,3.59,0,0,1,7.14.8,25.54,25.54,0,0,0,1.45,11.87,3.6,3.6,0,0,1-3.35,4.9Z"
                  fill="#8da83d"
                />
                <path
                  d="M379,251.16a3.67,3.67,0,0,1-1.21-.21,3.6,3.6,0,0,1-2.17-4.6c2.49-7,3.55-15.33,3.13-24.81a3.59,3.59,0,1,1,7.18-.31c.46,10.41-.73,19.68-3.54,27.54A3.61,3.61,0,0,1,379,251.16Z"
                  fill="#8da83d"
                />
                <path
                  d="M428.22,224a3.6,3.6,0,0,1-3.58-3.25,33.9,33.9,0,0,1,1.85-14.11,3.59,3.59,0,1,1,6.8,2.33,26.72,26.72,0,0,0-1.49,11.09,3.59,3.59,0,0,1-3.23,3.93Z"
                  fill="#8da83d"
                />
                <path
                  d="M401.55,263.76h-.25a3.59,3.59,0,0,1-3.34-3.83c.18-2.81.45-5.56.71-8.3.49-5,.95-9.69.95-14.72a3.6,3.6,0,1,1,7.19,0c0,5.37-.5,10.48-1,15.41-.26,2.67-.52,5.36-.71,8.09A3.6,3.6,0,0,1,401.55,263.76Z"
                  fill="#8da83d"
                />
                <path
                  d="M417.38,297.69a3.6,3.6,0,0,1-3.13-5.36l1.71-3c3.57-6.27,7.26-12.74,8.76-19.3a3.6,3.6,0,0,1,7,1.61c-1.74,7.59-5.87,14.84-9.53,21.25l-1.68,3A3.59,3.59,0,0,1,417.38,297.69Z"
                  fill="#8da83d"
                />
                <path
                  d="M379,311.26a3.6,3.6,0,0,1-1.06-7c3.63-1.13,7.61-7.12,8.34-12.55a3.59,3.59,0,1,1,7.12.95c-1,7.34-6.24,16.26-13.33,18.47A3.56,3.56,0,0,1,379,311.26Z"
                  fill="#8da83d"
                />
                <path
                  d="M363.19,296.72a3.6,3.6,0,0,1-2.4-6.27c4.31-3.88,5.67-8.33,7.25-14.68a3.6,3.6,0,1,1,7,1.74c-1.79,7.16-3.61,13.05-9.42,18.29A3.64,3.64,0,0,1,363.19,296.72Z"
                  fill="#8da83d"
                />
                <path
                  d="M354,259.88a3.59,3.59,0,0,1-3.07-5.47,26.39,26.39,0,0,0,3.7-8.46,3.6,3.6,0,0,1,7.08,1.28,33,33,0,0,1-4.64,10.92A3.57,3.57,0,0,1,354,259.88Z"
                  fill="#8da83d"
                />
                <path
                  d="M330.68,325.8a3.6,3.6,0,0,1,0-7.19c7-.05,11.18-.58,17.71-3.55a3.59,3.59,0,1,1,3,6.54c-7.63,3.48-12.94,4.14-20.64,4.2Z"
                  fill="#8da83d"
                />
                <path
                  d="M340.68,347.13a3.6,3.6,0,0,1-.41-7.17c3.82-.44,7.58-1.8,11.57-3.24a62.44,62.44,0,0,1,12.53-3.54,3.6,3.6,0,0,1,1,7.13,56.09,56.09,0,0,0-11.07,3.17,58.86,58.86,0,0,1-13.18,3.62Z"
                  fill="#8da83d"
                />
                <path
                  d="M404.05,335.49a3.59,3.59,0,0,1-1.45-6.88c7.73-3.41,14.57-8.52,21.8-13.94l4.19-3.12a3.59,3.59,0,1,1,4.26,5.79l-4.14,3.09c-7.24,5.42-14.73,11-23.22,14.76A3.51,3.51,0,0,1,404.05,335.49Z"
                  fill="#8da83d"
                />
                <path
                  d="M454.9,292.84a3.65,3.65,0,0,1-2.06-.64,3.61,3.61,0,0,1-.89-5c4.81-6.89,9.55-14,14.09-21.19a3.59,3.59,0,1,1,6.07,3.84c-4.59,7.26-9.39,14.48-14.26,21.46A3.59,3.59,0,0,1,454.9,292.84Z"
                  fill="#8da83d"
                />
                <path
                  d="M452.4,234.68a3.44,3.44,0,0,1-.77-.09,3.6,3.6,0,0,1-2.74-4.28,38.47,38.47,0,0,1,4.28-10.17c1.89-3.47,3.68-6.76,4-10.67a3.6,3.6,0,1,1,7.17.57c-.43,5.43-2.77,9.74-4.83,13.54a32.65,32.65,0,0,0-3.58,8.28A3.59,3.59,0,0,1,452.4,234.68Z"
                  fill="#8da83d"
                />
                <path
                  d="M444.9,175.54a4,4,0,0,1-1-.14,3.6,3.6,0,0,1-2.44-4.46c1-3.35.85-8.54.75-13.12,0-1.48-.06-2.92-.06-4.29a3.6,3.6,0,1,1,7.19,0c0,1.32,0,2.71.06,4.13.12,5.35.24,10.87-1,15.29A3.6,3.6,0,0,1,444.9,175.54Z"
                  fill="#8da83d"
                />
                <path
                  d="M444.06,121.25a3.59,3.59,0,0,1-3.59-3.5,20.17,20.17,0,0,0-1.47-6.13,25,25,0,0,1-1.86-9.63,3.46,3.46,0,0,1,3.75-3.43,3.58,3.58,0,0,1,3.43,3.75,18.52,18.52,0,0,0,1.48,7,27,27,0,0,1,1.86,8.31,3.59,3.59,0,0,1-3.5,3.68Z"
                  fill="#8da83d"
                />
                <path
                  d="M469.08,162h-.15a3.6,3.6,0,0,1-3.45-3.74c.38-9.2-1.48-22.17-5.25-27.94a3.6,3.6,0,0,1,6-3.94c5.34,8.19,6.77,23.73,6.42,32.17A3.6,3.6,0,0,1,469.08,162Z"
                  fill="#8da83d"
                />
                <path
                  d="M475.75,208.5a3.6,3.6,0,0,1-3.6-3.59V185.52a3.6,3.6,0,1,1,7.19,0v19.39A3.59,3.59,0,0,1,475.75,208.5Z"
                  fill="#8da83d"
                />
                <path
                  d="M32.21,223A3.6,3.6,0,0,1,29,221.16c-3.54-6.55-3.28-15.09-3.05-22.62.06-1.81.12-3.58.12-5.27a3.59,3.59,0,0,1,3.59-3.59h0a3.6,3.6,0,0,1,3.6,3.59c0,1.77-.06,3.61-.12,5.49-.21,7-.44,14.14,2.19,19A3.59,3.59,0,0,1,32.21,223Z"
                  fill="#8da83d"
                />
                <path
                  d="M58.88,235.65a3.59,3.59,0,0,1-3.58-3.31A65.79,65.79,0,0,0,52.57,220c-.8-2.78-1.62-5.65-2.21-8.53a3.59,3.59,0,1,1,7-1.44c.54,2.61,1.28,5.22,2.08,8a71.67,71.67,0,0,1,3,13.78,3.59,3.59,0,0,1-3.29,3.87Z"
                  fill="#8da83d"
                />
                <path
                  d="M51.38,267.63a3.57,3.57,0,0,1-2.66-1.17c-4.31-4.75-9.09-13.13-8.43-21.13a3.6,3.6,0,0,1,7.17.59c-.44,5.31,3.1,11.87,6.58,15.7a3.59,3.59,0,0,1-2.66,6Z"
                  fill="#8da83d"
                />
                <path
                  d="M80.56,286.05a3.59,3.59,0,0,1-3-1.59c-1.74-2.61-6.22-7-10-9.77a3.6,3.6,0,0,1,4.27-5.79c3.8,2.81,9.2,7.82,11.69,11.56a3.59,3.59,0,0,1-3,5.59Z"
                  fill="#8da83d"
                />
                <path
                  d="M85.57,255a3.6,3.6,0,0,1-2.93-1.49c-5.31-7.39-7.38-14.35-6.49-21.91a3.6,3.6,0,0,1,7.15.84c-.67,5.67,1,11,5.18,16.87a3.59,3.59,0,0,1-.82,5A3.54,3.54,0,0,1,85.57,255Z"
                  fill="#8da83d"
                />
                <path
                  d="M95.57,182.33h-.12A3.61,3.61,0,0,1,92,178.61c.2-6-1.45-14.63-3.17-20.13a3.6,3.6,0,1,1,6.87-2.15c1.84,5.89,3.72,15.45,3.49,22.52A3.6,3.6,0,0,1,95.57,182.33Z"
                  fill="#8da83d"
                />
                <path
                  d="M71.39,185.24a3.59,3.59,0,0,1-3.59-3.42,112.77,112.77,0,0,1,4.32-36.13,3.6,3.6,0,0,1,6.88,2.1,106.68,106.68,0,0,0-4,33.67,3.59,3.59,0,0,1-3.41,3.77Z"
                  fill="#8da83d"
                />
                <path
                  d="M49.72,169.72a3.59,3.59,0,0,1-3.57-4.06,96.6,96.6,0,0,1,3.24-14c.64-2.24,1.28-4.48,1.83-6.73a3.6,3.6,0,0,1,7,1.7c-.57,2.35-1.23,4.67-1.9,7a90.16,90.16,0,0,0-3,13A3.61,3.61,0,0,1,49.72,169.72Z"
                  fill="#8da83d"
                />
                <path
                  d="M75.56,116.41h0A3.6,3.6,0,0,1,72,112.8c0-13.93,14.21-25.25,18.86-29a3.6,3.6,0,1,1,4.49,5.62c-10.85,8.67-16.14,16.31-16.16,23.37A3.59,3.59,0,0,1,75.56,116.41Z"
                  fill="#8da83d"
                />
                <path
                  d="M98.07,122.22a3.69,3.69,0,0,1-.87-.1,3.6,3.6,0,0,1-2.62-4.36c1.42-5.69,4.6-10.25,7.41-14.28.76-1.1,1.52-2.19,2.23-3.29a3.6,3.6,0,0,1,6,3.92c-.76,1.16-1.56,2.32-2.37,3.48-2.69,3.86-5.23,7.51-6.33,11.91A3.59,3.59,0,0,1,98.07,122.22Z"
                  fill="#8da83d"
                />
                <path
                  d="M121.41,81.51a3.64,3.64,0,0,1-1.62-.39,3.6,3.6,0,0,1-1.59-4.83c1.92-3.8,5.54-6.45,8.74-8.79a47.31,47.31,0,0,0,3.73-2.92,3.6,3.6,0,1,1,4.82,5.34,54.66,54.66,0,0,1-4.3,3.38c-2.67,2-5.43,4-6.57,6.23A3.58,3.58,0,0,1,121.41,81.51Z"
                  fill="#8da83d"
                />
                <path
                  d="M133.92,157.12a3.59,3.59,0,0,1-3.51-2.81c-1.65-7.4-1.38-15.26-1.11-22.85.1-2.7.19-5.38.19-8a3.6,3.6,0,0,1,7.19,0c0,2.69-.09,5.45-.19,8.24-.25,7.15-.51,14.53.94,21A3.6,3.6,0,0,1,134.7,157,3.5,3.5,0,0,1,133.92,157.12Z"
                  fill="#8da83d"
                />
                <path
                  d="M126.42,216.26a3.61,3.61,0,0,1-3.36-2.29,81.24,81.24,0,0,1-5.24-31.5,3.59,3.59,0,1,1,7.18.28,74.6,74.6,0,0,0,4.77,28.6,3.6,3.6,0,0,1-2.05,4.66A3.53,3.53,0,0,1,126.42,216.26Z"
                  fill="#8da83d"
                />
                <path
                  d="M97.23,236.61a3.6,3.6,0,0,1-3.49-2.73c-1-3.92-2.19-8-3.38-12-.53-1.79-1.07-3.57-1.58-5.34a3.6,3.6,0,1,1,6.91-2q.77,2.63,1.56,5.27c1.21,4,2.46,8.22,3.48,12.31a3.61,3.61,0,0,1-2.63,4.36A3.69,3.69,0,0,1,97.23,236.61Z"
                  fill="#8da83d"
                />
                <path
                  d="M123.91,275.39a3.58,3.58,0,0,1-2.3-.83c-4-3.36-6.09-7.67-8.09-11.84a50,50,0,0,0-3.43-6.37,3.6,3.6,0,1,1,6-4,55.85,55.85,0,0,1,4,7.27c1.74,3.63,3.38,7.07,6.21,9.42a3.6,3.6,0,0,1-2.31,6.36Z"
                  fill="#8da83d"
                />
                <path
                  d="M150.6,265.7a3.59,3.59,0,0,1-3.21-2c-2.12-4.15-3.88-8.53-5.59-12.77-1.18-2.92-2.36-5.85-3.65-8.7a3.6,3.6,0,0,1,6.55-3c1.33,2.94,2.55,6,3.77,9,1.65,4.09,3.36,8.33,5.33,12.2a3.6,3.6,0,0,1-1.58,4.84A3.64,3.64,0,0,1,150.6,265.7Z"
                  fill="#8da83d"
                />
                <path
                  d="M148.09,215.29a3.61,3.61,0,0,1-3.49-2.72c-1.11-4.4-2.31-8.78-3.52-13.15-1.38-5-2.75-10-4-15a3.59,3.59,0,0,1,7-1.71c1.21,4.94,2.57,9.86,3.93,14.78,1.22,4.43,2.44,8.86,3.57,13.31a3.59,3.59,0,0,1-2.61,4.37A3.37,3.37,0,0,1,148.09,215.29Z"
                  fill="#8da83d"
                />
                <path
                  d="M76.39,207.53a3.59,3.59,0,0,1-3.59-3.59v-6.79a3.6,3.6,0,0,1,7.19,0v6.79A3.6,3.6,0,0,1,76.39,207.53Z"
                  fill="#8da83d"
                />
                <path
                  d="M127.25,294.78a3.37,3.37,0,0,1-.88-.11c-6-1.49-11.08-5.39-15.6-8.81l-.7-.54a3.59,3.59,0,0,1,4.34-5.73l.7.53c4.18,3.17,8.49,6.44,13,7.58a3.59,3.59,0,0,1-.87,7.08Z"
                  fill="#8da83d"
                />
                <path
                  d="M130.58,322.89a3.55,3.55,0,0,1-1.1-.17c-9.64-3.11-19-6.45-27.06-13.27a3.6,3.6,0,0,1,4.63-5.51c7.11,6,15.37,8.95,24.63,11.93a3.59,3.59,0,0,1-1.1,7Z"
                  fill="#8da83d"
                />
                <path
                  d="M180.61,321a3.17,3.17,0,0,1-.6-.05c-7.61-1.27-15-5.21-22-9.13a3.6,3.6,0,1,1,3.53-6.27c6.41,3.62,13.21,7.23,19.67,8.31a3.59,3.59,0,0,1-.59,7.14Z"
                  fill="#8da83d"
                />
                <path
                  d="M189.78,262.79a3.6,3.6,0,0,1-3.36-2.31c-1.58-4.12-2.89-8.44-4.16-12.61s-2.6-8.53-4.17-12.58a3.6,3.6,0,1,1,6.71-2.6c1.66,4.3,3,8.77,4.34,13.09s2.51,8.24,4,12.13a3.61,3.61,0,0,1-3.36,4.88Z"
                  fill="#8da83d"
                />
                <path
                  d="M209.79,243.4a3.62,3.62,0,0,1-3.37-2.32,45.18,45.18,0,0,1-2.25-11.44,37.75,37.75,0,0,0-1.9-9.85,3.6,3.6,0,0,1,6.7-2.62,44.12,44.12,0,0,1,2.35,11.71,39,39,0,0,0,1.83,9.66,3.59,3.59,0,0,1-2.09,4.63A3.56,3.56,0,0,1,209.79,243.4Z"
                  fill="#8da83d"
                />
                <path
                  d="M229,201.71a3.6,3.6,0,0,1-3.59-3.4c-.56-10.42-.82-18.55-.84-26.36a3.6,3.6,0,0,1,3.59-3.6h0a3.58,3.58,0,0,1,3.59,3.58c0,7.68.28,15.7.84,26a3.6,3.6,0,0,1-3.4,3.78Z"
                  fill="#8da83d"
                />
                <path
                  d="M233.13,269.58a3.6,3.6,0,0,1-3.59-3.6V241.74a3.6,3.6,0,0,1,7.19,0V266A3.6,3.6,0,0,1,233.13,269.58Z"
                  fill="#8da83d"
                />
                <path
                  d="M255.64,234.68a3.59,3.59,0,0,1-3.59-3.59c0-1.39,0-2.8.06-4.21.13-5,.24-9.71-1.46-13.81a3.6,3.6,0,1,1,6.65-2.75c2.28,5.5,2.14,11.22,2,16.74,0,1.34-.07,2.68-.06,4a3.61,3.61,0,0,1-3.58,3.61Z"
                  fill="#8da83d"
                />
                <path
                  d="M269.82,282.18a3.6,3.6,0,0,1-3.6-3.6V251.44a3.6,3.6,0,1,1,7.19,0v27.14A3.6,3.6,0,0,1,269.82,282.18Z"
                  fill="#8da83d"
                />
                <path
                  d="M299.83,266.67a3.6,3.6,0,0,1-3.6-3.6V235.93a3.6,3.6,0,0,1,7.2,0v27.14A3.6,3.6,0,0,1,299.83,266.67Z"
                  fill="#8da83d"
                />
                <path
                  d="M312.33,292.84a3.53,3.53,0,0,1-1.84-.51,3.6,3.6,0,0,1-1.24-4.93l.8-1.32c2.18-3.58,4.25-7,3.72-10.9a3.6,3.6,0,0,1,7.13-.95c.86,6.47-2.23,11.53-4.72,15.6l-.76,1.26A3.57,3.57,0,0,1,312.33,292.84Z"
                  fill="#8da83d"
                />
                <path
                  d="M334,304.47a3.59,3.59,0,0,1-2.27-6.38,53.69,53.69,0,0,0,6.69-7.21,76.29,76.29,0,0,1,6.46-7.17,3.59,3.59,0,1,1,4.9,5.26,72,72,0,0,0-5.82,6.49,58.8,58.8,0,0,1-7.7,8.21A3.59,3.59,0,0,1,334,304.47Z"
                  fill="#8da83d"
                />
                <path
                  d="M198.78,292.84c-7.63,0-11.67-4.87-15.94-10a3.59,3.59,0,0,1,5.54-4.58c4.14,5,6.33,7.42,10.41,7.42H199a3.6,3.6,0,0,1,0,7.19Z"
                  fill="#8da83d"
                />
                <path
                  d="M214.79,338.4a2.48,2.48,0,0,1-.38,0,48.39,48.39,0,0,1-7.17-1.49,28.86,28.86,0,0,0-8.2-1.4,3.6,3.6,0,0,1-.19-7.19,38.61,38.61,0,0,1,10.22,1.63,41.72,41.72,0,0,0,6.1,1.3,3.59,3.59,0,0,1-.38,7.17Z"
                  fill="#8da83d"
                />
                <path
                  d="M259.81,347.13a3.5,3.5,0,0,1-1.26-.23c-4-1.48-8.88-1.56-13.63-1.63-1.22,0-2.42,0-3.58-.08a3.6,3.6,0,1,1,.26-7.19c1.11,0,2.26.06,3.43.08,5.11.08,10.9.17,16,2.08a3.6,3.6,0,0,1-1.26,7Z"
                  fill="#8da83d"
                />
                <path
                  d="M303.55,348.09c-3.18,0-6.77-.25-10.65-.57-2.49-.2-4.83-.39-6.41-.39a3.6,3.6,0,1,1,0-7.19c1.88,0,4.24.19,7,.41,5.27.43,15.09,1.23,17.48-.63a3.6,3.6,0,0,1,4.42,5.68C312.75,347.44,308.67,348.09,303.55,348.09Z"
                  fill="#8da83d"
                />
                <path
                  d="M230.63,318.05a3.59,3.59,0,0,1-3.32-2.23c-.87-2.1-3-3.91-5.23-5.82-4-3.38-8.87-7.58-8.38-15.17a3.68,3.68,0,0,1,3.82-3.36,3.59,3.59,0,0,1,3.36,3.82c-.24,3.81,2.07,6,5.88,9.24,2.66,2.27,5.67,4.85,7.2,8.55a3.59,3.59,0,0,1-2,4.69A3.5,3.5,0,0,1,230.63,318.05Z"
                  fill="#8da83d"
                />
                <path
                  d="M246.47,315.14a3.59,3.59,0,0,1-3.36-2.33c-2.62-6.95-5.38-17.61-5.24-24.61a3.6,3.6,0,1,1,7.19.15c-.11,5.78,2.32,15.4,4.78,21.92a3.6,3.6,0,0,1-2.1,4.64A3.56,3.56,0,0,1,246.47,315.14Z"
                  fill="#8da83d"
                />
                <path
                  d="M269,319a3.6,3.6,0,0,1-3.59-3.6V300.88a3.6,3.6,0,1,1,7.19,0v14.54A3.6,3.6,0,0,1,269,319Z"
                  fill="#8da83d"
                />
                <path
                  d="M283.15,327.74a3.59,3.59,0,0,1-3.09-5.43c2.65-4.46,5.08-9.26,7.42-13.9,1.87-3.7,3.81-7.52,5.88-11.22a3.59,3.59,0,1,1,6.27,3.5c-2,3.57-3.89,7.33-5.73,11-2.4,4.74-4.88,9.65-7.65,14.32A3.61,3.61,0,0,1,283.15,327.74Z"
                  fill="#8da83d"
                />
                <path
                  d="M187.28,131.92a3.59,3.59,0,0,1-3.6-3.52,68.11,68.11,0,0,1,3.54-24.55,3.6,3.6,0,1,1,6.78,2.42c-2.31,6.47-3.3,13.45-3.13,22a3.6,3.6,0,0,1-3.52,3.67Z"
                  fill="#8da83d"
                />
                <path
                  d="M213.12,122.22a3.59,3.59,0,0,1-3.59-3.59V100.21a3.6,3.6,0,1,1,7.19,0v18.42A3.59,3.59,0,0,1,213.12,122.22Z"
                  fill="#8da83d"
                />
                <path
                  d="M247.3,121.25a3.6,3.6,0,0,1-3.59-3.48A151.52,151.52,0,0,0,238,80.83a3.6,3.6,0,1,1,6.92-2,158.85,158.85,0,0,1,6,38.68,3.59,3.59,0,0,1-3.49,3.7Z"
                  fill="#8da83d"
                />
                <path
                  d="M271.48,101.87a3.59,3.59,0,0,1-3.56-3.15c-.55-4.31-1.35-9-2.46-14.25a3.6,3.6,0,0,1,7-1.48c1.15,5.47,2,10.32,2.55,14.83a3.59,3.59,0,0,1-3.11,4Z"
                  fill="#8da83d"
                />
                <path
                  d="M360.69,94.11a3.58,3.58,0,0,1-3.59-3.58,67.42,67.42,0,0,0-3.16-20.23,3.6,3.6,0,1,1,6.84-2.22,73.56,73.56,0,0,1,3.51,22.43,3.6,3.6,0,0,1-3.59,3.6Z"
                  fill="#8da83d"
                />
                <path
                  d="M393.21,82.48a3.6,3.6,0,0,1-3.38-2.37c-1.89-5.17-7.67-9.94-11.95-11.39a3.6,3.6,0,0,1,2.31-6.82c5.89,2,13.65,8.21,16.39,15.75a3.6,3.6,0,0,1-2.14,4.61A3.55,3.55,0,0,1,393.21,82.48Z"
                  fill="#8da83d"
                />
                <path
                  d="M425.72,101.87a3.6,3.6,0,0,1-3.53-3c-1.1-5.92-3.6-10.07-6.5-14.88-1-1.72-2.1-3.48-3.13-5.38a3.6,3.6,0,1,1,6.32-3.44c1,1.8,2,3.47,3,5.1,3,5,6.08,10.09,7.41,17.3a3.6,3.6,0,0,1-2.89,4.19A3.66,3.66,0,0,1,425.72,101.87Z"
                  fill="#8da83d"
                />
                <path
                  d="M142.26,97a3.6,3.6,0,0,1-3.35-4.91c3.15-8,8.58-11.55,13.83-15,1.09-.71,2.19-1.42,3.3-2.2a3.6,3.6,0,1,1,4.11,5.9c-1.17.82-2.34,1.58-3.5,2.33-4.88,3.17-8.74,5.67-11.05,11.55A3.58,3.58,0,0,1,142.26,97Z"
                  fill="#8da83d"
                />
                <path
                  d="M173.1,87.33a3.45,3.45,0,0,1-1.32-.26,3.59,3.59,0,0,1-2-4.66c3.69-9.36,14.19-16.47,22.63-22.18,1.91-1.28,3.7-2.5,5.28-3.64a3.59,3.59,0,0,1,4.23,5.81c-1.64,1.19-3.5,2.45-5.47,3.79-7.63,5.15-17.11,11.57-20,18.86A3.61,3.61,0,0,1,173.1,87.33Z"
                  fill="#8da83d"
                />
                <path
                  d="M210.62,79.57a3.43,3.43,0,0,1-1-.14A3.59,3.59,0,0,1,207.16,75c3.17-11.07,8.72-19.26,16.5-24.33a3.6,3.6,0,1,1,3.93,6c-6.29,4.1-10.83,10.92-13.51,20.28A3.6,3.6,0,0,1,210.62,79.57Z"
                  fill="#8da83d"
                />
                <path
                  d="M157.26,56.3a3.6,3.6,0,0,1-2.53-6.15c4.41-4.36,10.18-5.47,15.28-6.45a54,54,0,0,0,5.39-1.22,3.59,3.59,0,0,1,2.08,6.88,59.87,59.87,0,0,1-6.11,1.4c-4.49.86-8.73,1.68-11.58,4.51A3.58,3.58,0,0,1,157.26,56.3Z"
                  fill="#8da83d"
                />
                <path
                  d="M284.83,62.12A3.44,3.44,0,0,1,284,62l-1.54-.36c-7.57-1.78-17.94-4.21-23.62-10.4a3.6,3.6,0,0,1,5.3-4.86c4.18,4.55,13.67,6.78,20,8.26l1.57.37a3.59,3.59,0,0,1-.83,7.09Z"
                  fill="#8da83d"
                />
                <path
                  d="M324.84,58.24a3.37,3.37,0,0,1-.88-.11c-1.61-.41-3.25-.72-4.88-1-5.28-1-11.27-2.19-16.51-6.44a3.59,3.59,0,1,1,4.53-5.58c3.84,3.11,8.46,4,13.35,5,1.77.34,3.53.69,5.27,1.13a3.59,3.59,0,0,1-.88,7.08Z"
                  fill="#8da83d"
                />
                <path
                  d="M357.36,55.33a3.39,3.39,0,0,1-1.39-.28,37,37,0,0,0-12.92-2.63,3.59,3.59,0,0,1-3.46-3.72,3.63,3.63,0,0,1,3.73-3.46,44,44,0,0,1,15.43,3.18,3.6,3.6,0,0,1-1.39,6.91Z"
                  fill="#8da83d"
                />
                <path
                  d="M312.33,95.08a3.6,3.6,0,0,1-3.5-2.77c-1.15-4.92-3.22-9-5.26-10.51A3.59,3.59,0,1,1,307.76,76c4.82,3.47,7.13,10.69,8.08,14.7A3.6,3.6,0,0,1,313.16,95,3.31,3.31,0,0,1,312.33,95.08Z"
                  fill="#8da83d"
                />
              </g>
            </g>
            <g>
              <path
                d="M325.72,35.27c-29.52-1.48-58.88-4.65-88.52-3.79-32.81,1-70.17-1.22-102,8.45S68.51,74.58,48.47,104.82a268.15,268.15,0,0,0-20.1,36l3.56-2.43c23.26-16.5,57.45,1.44,60,31.42,2.07,24.21-4.89,52.46-.39,76,6.42,33.56,55.36,39,77.93,38.74,17.91-.24,10.45-3.16,17.58-18.76,6.91-15.14,17.42-26.55,31.29-27.89,34-3.28,53.16,15,53.71,54.51,16.89-9.51,38.09-14.86,56.58-3.32,10.3,6.42,18.32,24.23,17.81,37.86,15.47,1.72,22.41-7.2,33.93,4.19,7.43,7.33,8.19,13.95,8.36,22.68a115.48,115.48,0,0,0,21.88-10.16C451.28,319,488,281.05,492.21,225,501,107.48,418.85,39.94,325.72,35.27Z"
                fill="none"
              />
              <path
                d="M380.39,331.07c-11.52-11.39-18.46-2.47-33.93-4.19C347,313.25,339,295.44,328.65,289c-18.49-11.54-39.69-6.19-56.58,3.32-.55-39.48-19.74-57.79-53.71-54.51-13.87,1.34-24.38,12.75-31.29,27.89-7.13,15.6.33,18.52-17.58,18.76-22.57.29-71.51-5.18-77.93-38.74-4.5-23.52,2.46-51.77.39-76-2.57-30-36.76-47.92-60-31.42l-3.56,2.43c-17.12,37.1-20.53,68.14-19.9,84.94,1.18,31.6,21.82,56.06,42.32,74.86,23,21.12,52.52,29.15,79.75,38.68,47.74,16.71,94.23,24.23,144.3,24.23,36,0,77.94,2.85,113.91-9.73C388.58,345,387.82,338.4,380.39,331.07Z"
                fill="#6a7c35"
                opacity="0.38"
                style={{ mixBlendMode: "multiply" }}
              />
            </g>
          </g>
        </g>
        <g id="cvetki" opacity="0">
          <g>
            <path
              d="M234.24,341.81c.12,8.56,12.55,5.06,6.17-2.57,6.13-.46,5.13-8-1.15-5.73,1.19-7.22-6.79-10.57-6.59-3.29-2.77-.27-7.1-1.38-8.24,2.11-1,3,1.73,5,4.66,4.8-1.59,1.95-5.29,5.55-1.42,7.9C230.27,346.62,230.87,344.93,234.24,341.81Z"
              fill="#f4e3e5"
            />
            <path
              d="M236.4,335.5c.48.6.15,3.08-.36,3.66a2,2,0,0,1-2.93-.28c-.59-.92-.35-3.18-.06-4,.2-.56.55-1,1.23-1C235.26,333.92,236.13,334,236.4,335.5Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M276.7,306c-1.13-8.49-13.05-3.53-5.82,3.28-6,1.18-4.14,8.54,1.83,5.55-.32,7.32,8,9.69,6.93,2.48,2.78-.06,7.22.53,7.94-3.07.61-3.09-2.32-4.75-5.21-4.21,1.35-2.13,4.6-6.14.48-8C280.07,300.73,279.68,302.48,276.7,306Z"
              fill="#e9b8be"
            />
            <path
              d="M275.31,312.49c-.54-.53-.51-3-.08-3.67a2,2,0,0,1,2.95-.07c.69.84.72,3.11.53,4-.13.58-.42,1.09-1.1,1.13C276.63,313.93,275.76,314,275.31,312.49Z"
              fill="#f1dfcb"
            />
          </g>
          <circle cx="342.54" cy="315.57" r="6.69" fill="#be5865" />
          <circle cx="376.05" cy="333.44" r="6.69" fill="#be5865" />
          <g>
            <path
              d="M233.65,279.31c-1.13-8.48-13.05-3.53-5.82,3.29-6,1.18-4.14,8.54,1.83,5.55-.32,7.31,8,9.69,6.93,2.48,2.78-.06,7.21.53,7.93-3.08.62-3.08-2.31-4.74-5.2-4.2,1.35-2.13,4.6-6.14.47-8C237,274.07,236.63,275.82,233.65,279.31Z"
              fill="#f4e3e5"
            />
            <path
              d="M232.26,285.83c-.55-.53-.51-3-.08-3.67a2,2,0,0,1,2.95-.07c.69.84.72,3.11.53,4-.13.57-.42,1.09-1.1,1.13C233.58,287.27,232.71,287.33,232.26,285.83Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M175.75,319c-7.66,3.84.91,13.49,5,4.43,3.08,5.32,9.42,1.14,4.65-3.53,7-2.08,6.56-10.71.09-7.36-1-2.61-1.85-7-5.49-6.5-3.12.42-3.73,3.73-2.28,6.29-2.46-.58-7.31-2.35-7.74,2.16C169.69,317.5,171.47,317.3,175.75,319Z"
              fill="#e9b8be"
            />
            <path
              d="M182.46,318.18c-.33.69-2.71,1.47-3.45,1.26a2,2,0,0,1-1-2.76c.56-.92,2.7-1.69,3.58-1.79a1.22,1.22,0,0,1,1.43.66C183.39,316.46,183.72,317.26,182.46,318.18Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M123,319.68c-7.65,3.84.92,13.5,5,4.44,3.07,5.31,9.42,1.14,4.65-3.53,7-2.08,6.55-10.72.09-7.37-1-2.61-1.85-7-5.49-6.5-3.12.42-3.73,3.73-2.29,6.29-2.45-.58-7.3-2.35-7.73,2.16C116.92,318.21,118.7,318,123,319.68Z"
              fill="#e9b8be"
            />
            <path
              d="M129.69,318.88c-.33.69-2.71,1.47-3.45,1.27a2,2,0,0,1-1-2.76c.56-.93,2.7-1.7,3.58-1.8a1.22,1.22,0,0,1,1.43.67C130.62,317.16,131,318,129.69,318.88Z"
              fill="#f1dfcb"
            />
          </g>
          <circle cx="178.04" cy="273.27" r="6.69" fill="#be5865" />
          <circle cx="238.64" cy="244.71" r="6.69" fill="#be5865" />
          <g>
            <path
              d="M318.34,260.6c-1.51,13-19.86,5.75-9-4.87-9.25-1.64-6.56-12.94,2.64-8.53-.68-11.17,12-15,10.54-4,4.25,0,11-1,12.21,4.5,1,4.7-3.41,7.31-7.85,6.57,2.12,3.22,7.2,9.26.94,12.24C323.63,268.54,323,265.87,318.34,260.6Z"
              fill="#f4e3e5"
            />
            <path
              d="M316,250.67c-.82.84-.7,4.66,0,5.62a3.06,3.06,0,0,0,4.51,0c1-1.3,1-4.77.7-6.09-.21-.88-.67-1.66-1.7-1.71C318,248.44,316.69,248.38,316,250.67Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M358.45,262.49c-1.52,13-19.87,5.74-9-4.88-9.24-1.64-6.56-12.94,2.64-8.53-.68-11.17,12-15,10.54-4,4.26,0,11-1,12.21,4.49,1,4.7-3.41,7.31-7.85,6.57,2.12,3.22,7.2,9.26.94,12.25C363.74,270.42,363.09,267.76,358.45,262.49Z"
              fill="#e9b8be"
            />
            <path
              d="M356.24,252.56c-.82.83-.7,4.65,0,5.61a3,3,0,0,0,4.5,0c1-1.3,1-4.77.71-6.09-.21-.88-.67-1.66-1.71-1.71C358.22,250.32,356.89,250.26,356.24,252.56Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M399,299.07c12.78-2.81,11.89,16.91-1.69,10.08,1.46,9.27-10.1,10.42-8.93.28-10.34,4.28-18.09-6.44-7.18-8.67-1.37-4-4.53-10.09.27-13,4.12-2.5,8,.84,8.77,5.28,2.35-3,6.41-9.82,11.27-4.87C404.78,291.48,402.47,293,399,299.07Z"
              fill="#e9b8be"
            />
            <path
              d="M390.45,304.48c1.05.5,4.63-.86,5.32-1.81a3,3,0,0,0-1.44-4.27c-1.57-.55-4.85.59-6,1.31a1.88,1.88,0,0,0-1.06,2.17C387.69,303.32,388.07,304.61,390.45,304.48Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M432.77,265.43c.11,8.56,12.54,5.06,6.16-2.57,6.13-.46,5.14-8-1.15-5.73,1.19-7.22-6.79-10.57-6.59-3.29-2.77-.27-7.1-1.38-8.24,2.11-1,3,1.73,5,4.67,4.8-1.6,1.95-5.3,5.55-1.43,7.91C428.79,270.24,429.39,268.55,432.77,265.43Z"
              fill="#f4e3e5"
            />
            <path
              d="M434.92,259.12c.48.6.15,3.08-.36,3.66a2,2,0,0,1-2.93-.28c-.58-.92-.35-3.18-.05-4,.19-.56.54-1,1.22-1C433.78,257.54,434.65,257.58,434.92,259.12Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M146.11,218.9c-12.92-2.05-4.9-20.09,5.25-8.77,2-9.17,13.21-6,8.41,3,11.19-.21,14.5,12.59,3.53,10.7-.2,4.25.53,11-5,12-4.75.82-7.17-3.72-6.24-8.12-3.3,2-9.56,6.8-12.27.42C138,223.86,140.65,223.33,146.11,218.9Z"
              fill="#f4e3e5"
            />
            <path
              d="M156.13,217c-.8-.85-4.62-.89-5.61-.25a3,3,0,0,0-.22,4.49c1.26,1.09,4.73,1.22,6.06,1,.88-.17,1.68-.6,1.78-1.64C158.28,219.1,158.4,217.77,156.13,217Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M142.54,259c-12.93-2.06-4.9-20.09,5.25-8.78,2-9.16,13.21-6,8.41,3,11.19-.21,14.5,12.6,3.53,10.7-.2,4.25.53,11-5,12-4.75.82-7.17-3.72-6.24-8.12-3.3,2-9.56,6.8-12.27.42C134.39,263.94,137.08,263.41,142.54,259Z"
              fill="#f4e3e5"
            />
            <path
              d="M152.56,257.1c-.8-.85-4.62-.89-5.61-.25a3,3,0,0,0-.22,4.5c1.25,1.08,4.72,1.22,6.05,1,.89-.17,1.69-.6,1.79-1.63C154.71,259.18,154.83,257.85,152.56,257.1Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M76.89,227.68c-12.92-2.05-4.9-20.09,5.25-8.77,2-9.17,13.21-6,8.41,3,11.19-.21,14.5,12.59,3.53,10.7-.2,4.25.53,11-5,12-4.75.82-7.17-3.72-6.24-8.12-3.3,2-9.56,6.8-12.27.42C68.74,232.64,71.43,232.1,76.89,227.68Z"
              fill="#f4e3e5"
            />
            <path
              d="M86.91,225.8c-.8-.85-4.62-.89-5.61-.26a3,3,0,0,0-.22,4.5c1.25,1.09,4.73,1.22,6.06,1,.88-.17,1.68-.6,1.78-1.64C89.06,227.88,89.18,226.55,86.91,225.8Z"
              fill="#f1dfcb"
            />
          </g>
          <circle cx="225.62" cy="214.13" r="6.69" fill="#be5865" />
          <g>
            <path
              d="M122.79,177.05c8.52-.78,4.08-12.89-3-6-.93-6.07-8.36-4.5-5.62,1.59-7.3-.63-10,7.58-2.77,6.82-.06,2.78-.83,7.19,2.74,8.06,3.06.74,4.83-2.11,4.42-5,2.07,1.44,5.94,4.86,8,.82C127.89,180.64,126.16,180.18,122.79,177.05Z"
              fill="#e9b8be"
            />
            <path
              d="M116.43,175.38c.56-.52,3-.38,3.67.08a2,2,0,0,1,0,2.95c-.87.65-3.14.59-4,.36-.57-.15-1.07-.46-1.09-1.14C114.94,176.65,114.92,175.78,116.43,175.38Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M327.83,175.07c.11,8.56,12.54,5.06,6.17-2.57,6.12-.45,5.13-8-1.15-5.72,1.18-7.23-6.79-10.57-6.6-3.29-2.77-.27-7.1-1.38-8.24,2.11-1,3,1.73,5,4.67,4.79-1.59,2-5.3,5.55-1.43,7.91C323.86,179.88,324.45,178.19,327.83,175.07Z"
              fill="#f4e3e5"
            />
            <path
              d="M330,168.77c.47.59.14,3.07-.37,3.65a2,2,0,0,1-2.93-.28c-.58-.91-.34-3.17-.05-4a1.23,1.23,0,0,1,1.22-1C328.84,167.18,329.71,167.23,330,168.77Z"
              fill="#f1dfcb"
            />
          </g>
          <circle cx="387.2" cy="208.31" r="6.69" fill="#be5865" />
          <g>
            <path
              d="M446.26,208c12.79-2.8,11.9,16.91-1.68,10.08,1.46,9.28-10.1,10.42-8.93.28-10.34,4.28-18.09-6.43-7.19-8.67-1.36-4-4.52-10.09.28-13,4.11-2.5,8,.85,8.76,5.28,2.36-3.05,6.42-9.82,11.28-4.87C452,200.44,449.73,201.92,446.26,208Z"
              fill="#e9b8be"
            />
            <path
              d="M437.72,213.44c1,.5,4.63-.86,5.31-1.81a3.05,3.05,0,0,0-1.43-4.27c-1.57-.55-4.85.59-6,1.31a1.88,1.88,0,0,0-1.06,2.17C435,212.29,435.33,213.57,437.72,213.44Z"
              fill="#f1dfcb"
            />
          </g>
          <circle cx="477.62" cy="200.55" r="6.69" fill="#be5865" />
          <g>
            <path
              d="M383,157c12.79-2.8,11.9,16.92-1.68,10.08,1.46,9.28-10.1,10.42-8.93.28-10.34,4.28-18.1-6.43-7.19-8.67-1.36-4-4.52-10.09.28-13,4.11-2.5,8,.85,8.76,5.28,2.36-3.05,6.42-9.82,11.28-4.87C388.79,149.39,386.48,150.87,383,157Z"
              fill="#f4e3e5"
            />
            <path
              d="M374.37,162.39c1.06.5,4.63-.86,5.32-1.81a3,3,0,0,0-1.44-4.27c-1.56-.55-4.84.59-6,1.31a1.9,1.9,0,0,0-1.06,2.18C371.61,161.24,372,162.52,374.37,162.39Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M277.57,145.48c3.05-12.73,20.41-3.34,8.34,5.91,9,2.73,5,13.63-3.63,8.16-.66,11.17-13.68,13.48-10.94,2.69-4.22-.53-11.06-.32-11.59-5.91-.46-4.8,4.25-6.86,8.57-5.59-1.72-3.45-6-10.06.53-12.28C273.26,137,273.58,139.7,277.57,145.48Z"
              fill="#e9b8be"
            />
            <path
              d="M278.77,155.61c.91-.73,1.25-4.54.69-5.57a3,3,0,0,0-4.47-.56c-1.18,1.16-1.58,4.61-1.43,6,.11.9.47,1.72,1.49,1.9C276.53,157.6,277.85,157.82,278.77,155.61Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M210.62,160.07c12.78-2.8,11.89,16.92-1.69,10.09,1.46,9.27-10.1,10.42-8.92.28-10.35,4.27-18.1-6.44-7.19-8.68-1.37-4-4.53-10.09.27-13,4.12-2.5,8,.84,8.77,5.28,2.35-3,6.42-9.82,11.27-4.88C216.4,152.49,214.09,154,210.62,160.07Z"
              fill="#f4e3e5"
            />
            <path
              d="M202,165.48c1.05.5,4.63-.85,5.31-1.81a3,3,0,0,0-1.43-4.26c-1.57-.56-4.85.58-6,1.31a1.89,1.89,0,0,0-1.07,2.17C199.22,164.33,199.59,165.61,202,165.48Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M457.64,163.64c.12,8.56,12.55,5.06,6.17-2.57,6.13-.46,5.13-8-1.15-5.73,1.19-7.22-6.79-10.57-6.59-3.29-2.77-.27-7.1-1.38-8.24,2.11-1,3,1.73,5,4.67,4.8-1.6,1.95-5.3,5.55-1.43,7.91C453.67,168.45,454.27,166.76,457.64,163.64Z"
              fill="#f4e3e5"
            />
            <path
              d="M459.8,157.33c.48.6.15,3.08-.36,3.66a2,2,0,0,1-2.93-.28c-.59-.92-.35-3.18-.06-4a1.24,1.24,0,0,1,1.23-1C458.66,155.75,459.53,155.8,459.8,157.33Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M22.91,230.52c-7.65,3.83.92,13.49,5,4.43,3.07,5.31,9.42,1.14,4.65-3.53,7-2.08,6.55-10.72.09-7.37-1-2.61-1.85-7-5.49-6.5-3.12.42-3.73,3.73-2.29,6.29-2.45-.58-7.3-2.35-7.73,2.16C16.86,229,18.64,228.84,22.91,230.52Z"
              fill="#e9b8be"
            />
            <path
              d="M29.63,229.71c-.33.69-2.71,1.47-3.45,1.27a2,2,0,0,1-1-2.76c.56-.93,2.7-1.7,3.58-1.8a1.22,1.22,0,0,1,1.43.67C30.56,228,30.89,228.8,29.63,229.71Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M151.35,140.63c8.53-.77,4.08-12.89-3-5.95-.92-6.07-8.35-4.5-5.62,1.59-7.29-.63-10,7.58-2.77,6.83,0,2.78-.82,7.18,2.74,8.05,3.06.75,4.84-2.11,4.43-5,2.07,1.43,5.94,4.85,8,.81C156.46,144.22,154.72,143.76,151.35,140.63Z"
              fill="#f4e3e5"
            />
            <path
              d="M144.9,139c.56-.52,3.06-.38,3.67.08a2,2,0,0,1,0,2.94c-.87.65-3.14.59-4,.36-.57-.15-1.07-.46-1.09-1.14C143.41,140.23,143.39,139.36,144.9,139Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M113.55,111.32c3-12.72,20.41-3.33,8.35,5.91,9,2.73,5,13.63-3.64,8.16-.66,11.17-13.68,13.49-10.94,2.69-4.22-.52-11-.32-11.59-5.91-.46-4.8,4.26-6.86,8.57-5.59-1.72-3.45-6-10.06.53-12.27C109.24,102.82,109.57,105.54,113.55,111.32Z"
              fill="#f4e3e5"
            />
            <path
              d="M114.66,121.46c.91-.73,1.25-4.54.69-5.57a3,3,0,0,0-4.47-.57c-1.18,1.17-1.58,4.62-1.43,6a1.87,1.87,0,0,0,1.49,1.9C112.42,123.44,113.74,123.66,114.66,121.46Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M67.23,149.33c3-12.73,20.4-3.34,8.34,5.91,9,2.73,5,13.63-3.64,8.15-.66,11.18-13.67,13.49-10.94,2.7-4.22-.53-11.05-.33-11.59-5.92-.45-4.79,4.26-6.85,8.58-5.59-1.72-3.44-6-10.05.52-12.27C62.92,140.82,63.24,143.54,67.23,149.33Z"
              fill="#e9b8be"
            />
            <path
              d="M68.43,159.46c.91-.73,1.24-4.54.68-5.57a3.06,3.06,0,0,0-4.47-.57c-1.17,1.17-1.58,4.62-1.42,6a1.88,1.88,0,0,0,1.49,1.9C66.19,161.44,67.5,161.67,68.43,159.46Z"
              fill="#f1dfcb"
            />
          </g>
          <circle cx="99.12" cy="76.98" r="6.69" fill="#be5865" />
          <circle cx="416.11" cy="130.84" r="6.69" fill="#be5865" />
          <circle cx="408.98" cy="103.9" r="6.69" fill="#be5865" />
          <g>
            <path
              d="M381.57,61.52c3.05-12.73,20.41-3.34,8.35,5.9,9,2.73,5,13.64-3.64,8.16-.66,11.17-13.68,13.49-10.94,2.69-4.22-.52-11-.32-11.59-5.91-.46-4.79,4.26-6.86,8.57-5.59-1.72-3.45-6-10,.53-12.27C377.27,53,377.59,55.73,381.57,61.52Z"
              fill="#f4e3e5"
            />
            <path
              d="M382.68,71.65c.91-.73,1.25-4.54.69-5.57a3,3,0,0,0-4.47-.57c-1.18,1.17-1.58,4.62-1.43,6a1.87,1.87,0,0,0,1.49,1.9C380.44,73.63,381.76,73.85,382.68,71.65Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M191.67,109.57c3-12.73,20.4-3.34,8.34,5.91,9,2.73,5,13.63-3.64,8.16-.66,11.17-13.68,13.48-10.94,2.69-4.22-.53-11.05-.32-11.59-5.91-.45-4.8,4.26-6.86,8.58-5.59-1.72-3.45-6-10.06.52-12.27C187.36,101.07,187.68,103.79,191.67,109.57Z"
              fill="#f4e3e5"
            />
            <path
              d="M192.77,119.71c.91-.73,1.25-4.54.69-5.58a3,3,0,0,0-4.47-.56c-1.18,1.16-1.58,4.61-1.43,6a1.88,1.88,0,0,0,1.49,1.9C190.53,121.69,191.85,121.91,192.77,119.71Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M79.3,291.73c.12,8.56,12.54,5.06,6.17-2.57,6.12-.45,5.13-8-1.15-5.73,1.18-7.22-6.79-10.56-6.59-3.28-2.77-.27-7.1-1.38-8.24,2.11-1,3,1.73,5,4.66,4.79-1.59,2-5.3,5.55-1.42,7.91C75.33,296.54,75.93,294.85,79.3,291.73Z"
              fill="#f4e3e5"
            />
            <path
              d="M81.46,285.43c.48.59.14,3.07-.36,3.65a2,2,0,0,1-2.93-.28c-.59-.91-.35-3.17-.06-4a1.24,1.24,0,0,1,1.23-1C80.32,283.84,81.19,283.89,81.46,285.43Z"
              fill="#f1dfcb"
            />
          </g>
          <circle cx="46.57" cy="193.89" r="6.69" fill="#be5865" />
          <g>
            <path
              d="M153.68,63.38c.12,8.56,12.54,5.06,6.17-2.57,6.12-.45,5.13-8-1.15-5.72,1.18-7.23-6.79-10.57-6.6-3.29-2.76-.27-7.09-1.38-8.24,2.11-1,3,1.73,5,4.67,4.79-1.59,2-5.3,5.55-1.43,7.91C149.71,68.2,150.3,66.5,153.68,63.38Z"
              fill="#f4e3e5"
            />
            <path
              d="M155.84,57.08c.47.59.14,3.07-.36,3.65a2,2,0,0,1-2.94-.28c-.58-.91-.34-3.17,0-4a1.23,1.23,0,0,1,1.22-1C154.69,55.49,155.56,55.54,155.84,57.08Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M281.08,215.17c3.05-12.73,20.41-3.34,8.35,5.91,9,2.73,5,13.63-3.64,8.16-.66,11.17-13.68,13.48-10.94,2.69-4.22-.53-11.05-.32-11.59-5.91-.46-4.8,4.26-6.86,8.58-5.59-1.72-3.45-6-10.06.52-12.28C276.78,206.66,277.1,209.39,281.08,215.17Z"
              fill="#f4e3e5"
            />
            <path
              d="M282.19,225.31c.91-.74,1.25-4.54.69-5.58a3,3,0,0,0-4.47-.56c-1.18,1.16-1.58,4.61-1.43,6a1.88,1.88,0,0,0,1.49,1.9C280,227.29,281.27,227.51,282.19,225.31Z"
              fill="#f1dfcb"
            />
          </g>
          <circle cx="234.37" cy="90.45" r="6.69" fill="#be5865" />
          <circle cx="331.86" cy="64.79" r="6.69" fill="#be5865" />
          <g>
            <path
              d="M317.67,107.72c.12,8.56,12.54,5.06,6.17-2.57,6.12-.46,5.13-8-1.15-5.73,1.18-7.22-6.79-10.57-6.59-3.29-2.77-.27-7.1-1.38-8.25,2.11-1,3,1.74,5,4.67,4.8-1.59,1.95-5.3,5.55-1.42,7.9C313.7,112.53,314.3,110.84,317.67,107.72Z"
              fill="#f4e3e5"
            />
            <path
              d="M319.83,101.41c.47.6.14,3.08-.36,3.66a2,2,0,0,1-2.93-.28c-.59-.92-.35-3.18-.06-4,.19-.56.55-1,1.22-1C318.68,99.83,319.56,99.87,319.83,101.41Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M278.09,76.13c.12,8.55,12.55,5.05,6.17-2.58,6.13-.45,5.14-8-1.15-5.72,1.19-7.23-6.79-10.57-6.59-3.29-2.77-.27-7.1-1.38-8.24,2.11-1,3,1.73,5,4.67,4.8-1.6,2-5.3,5.54-1.43,7.9C274.12,80.94,274.72,79.24,278.09,76.13Z"
              fill="#f4e3e5"
            />
            <path
              d="M280.25,69.82c.48.59.15,3.07-.36,3.65a2,2,0,0,1-2.93-.28c-.58-.91-.35-3.17-.06-4a1.24,1.24,0,0,1,1.23-1C279.11,68.23,280,68.28,280.25,69.82Z"
              fill="#f1dfcb"
            />
          </g>
          <g>
            <path
              d="M227.84,46.53c3-12.73,20.41-3.34,8.34,5.91,9,2.73,5,13.63-3.64,8.16-.65,11.17-13.67,13.48-10.93,2.69-4.23-.53-11.06-.32-11.59-5.91-.46-4.8,4.25-6.86,8.57-5.59-1.72-3.45-6-10.06.52-12.28C223.53,38,223.85,40.75,227.84,46.53Z"
              fill="#e9b8be"
            />
            <path
              d="M229,56.66c.91-.73,1.25-4.53.69-5.57a3,3,0,0,0-4.47-.56c-1.18,1.16-1.58,4.61-1.43,6a1.88,1.88,0,0,0,1.49,1.9C226.8,58.65,228.12,58.87,229,56.66Z"
              fill="#f1dfcb"
            />
          </g>
        </g>
        <g id="bee" opacity="0">
          <g>
            <path
              d="M118.25,138.91c-1.86,3.32-4.71,5.42-7.79,5.34s-5.83-2.41-7.42-5.82a22.32,22.32,0,0,1,.06-16.68,12.44,12.44,0,0,1,3.44-4.52,18.05,18.05,0,0,0-3.74-4.77,1.13,1.13,0,1,1,1.53-1.66,20.92,20.92,0,0,1,4.16,5.25,9.13,9.13,0,0,1,2.93-.57,8.17,8.17,0,0,1,2.85.7,19.2,19.2,0,0,1,4.52-4.93c1.4-1.09,2.55,1,1.45,1.79a18.36,18.36,0,0,0-4,4.52,13.05,13.05,0,0,1,3.14,4.78A22.33,22.33,0,0,1,118.25,138.91Z"
              fill="#53210d"
            />
            <path
              d="M103.28,132.74a8.08,8.08,0,0,0,1,4.65c4.47.3,8.78.48,12.87.53a7,7,0,0,0,1.4-4.36C113.55,133.43,108.46,133.14,103.28,132.74Z"
              fill="#ffc713"
            />
            <path
              d="M104.52,122.38a8.79,8.79,0,0,0-1.08,4.62,52.64,52.64,0,0,1,15.54.34,16.82,16.82,0,0,0-1-4.49A46.09,46.09,0,0,0,104.52,122.38Z"
              fill="#ffc713"
            />
          </g>
          <g id="wings">
            <g>
              <path
                d="M115.91,137.66a7,7,0,0,1-2.21-4.25,20,20,0,0,1-.56-6.7c.31-1.89,1.07-3.44,2.68-4.18a6.48,6.48,0,0,1,3.52-.19c3.42.84,7.21,4.49,8.23,8.36,1.84,7-4.52,9.92-9.32,8.21A9.65,9.65,0,0,1,115.91,137.66Z"
                fill="#53210d"
              />
              <path
                d="M119.13,136.56c3.33,1.5,7.59-.48,6.24-5.49-.89-3.47-5-7.19-7.67-6.64s-2.35,4.71-1.46,8.17C116.65,133.69,117,135.57,119.13,136.56Z"
                fill="#fff"
              />
            </g>
            <g>
              <path
                d="M103,138.43c-4.86,1.37-11.05-2-8.78-8.88a13,13,0,0,1,8.84-7.8,4.67,4.67,0,0,1,5.85,4.83,33.11,33.11,0,0,1-1,6.56,8,8,0,0,1-2.59,4.12A6.81,6.81,0,0,1,103,138.43Z"
                fill="#53210d"
              />
              <path
                d="M105.69,132.2c1.12-3.35,1.66-7.44-.87-8.21s-7,2.66-8.1,6c-1.65,4.89,2.4,7.16,5.79,6C104.77,135.07,105.13,133.27,105.69,132.2Z"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default JablanaSVG;
