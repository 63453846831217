import { DragDropContainer } from "react-drag-drop-container";
import { forwardRef } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import iconInfo from "../assets/images/icon-info.svg";

const Trees = forwardRef((props, refDrag) => {
  const dragging = (e) => {
    //console.log("dragging", e);
  };

  return (
    <div className="treeBox">
      <DragDropContainer
        targetKey={props.targetKey}
        onDrag={dragging}
        dragData={{
          label: props.ident
        }}>
        <img className="treeImage" src={props.imageTree} alt="" />
      </DragDropContainer>
      <p className="color-secondary mb-0 fw-bold d-none d-sm-flex justify-content-center">
        {props.label}

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              <img className="treeImage rounded-1 mb-3" src={props.tooltipImage} alt="" />
              <p>{props.tooltipText}</p>
            </Tooltip>
          }>
          <img className="icon position-relative" src={iconInfo} />
        </OverlayTrigger>
      </p>
    </div>
  );
});

export default Trees;
