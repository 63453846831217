export const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1.2,
      type: "spring",
      bounce: 0.3,

    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
    transition: {
      duration: 1.2,
      type: "spring",
      bounce: 0.3

    },
  },
};


export const fadeInOut = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,

    },
  },
  exit: {
    opacity: 0,
  },
};