import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";


i18n.on('languageChanged', function (lng) {
  // if the language we switched to is the default language we need to remove the /en from URL
  if (lng === i18n.options.fallbackLng[0]) {
    if (window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
      const newUrl = window.location.pathname.replace('/' + i18n.options.fallbackLng[0], '')
      window.location.replace(newUrl)
    }
  }
})


i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "sl",
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    resources: {
      en: {
        translations: require("./locales/en/translations.json"),
      },
      sl: {
        translations: require("./locales/sl/translations.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });
  

  

i18n.languages = ["en", "sl"];

export default i18n;
