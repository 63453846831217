import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { fadeInOut } from "../../utils/animations";
import BG from "../../assets/images/BG-static.png";

import imgMed from "../../assets/images/pages/med.jpg";
import imgMaticni from "../../assets/images/pages/maticni_mlecek.jpg";
import imgPropolis from "../../assets/images/pages/propolis.jpg";
import imgCvetni from "../../assets/images/pages/cvetni_prah.jpg";
import imgSirupi from "../../assets/images/pages/sirupi.jpg";
import imgKozmetika from "../../assets/images/pages/kozmetika.jpg";

const Cookies = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="pages pb-5"
      style={{ backgroundImage: `url(${BG})` }}>
      <div className="container-xl mt-6">
        <div className="wrapper variant-one rounded">
          <div className="entry row g-3 g-sm-5 position-relative">
            {currentLanguage === "sl" && (
              <>
                <h1>
                  <strong>VEČ O PIŠKOTKIH</strong>
                </h1>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Na spletnih mestih družbe MEDEX TRADE d.o.o. (v
                    nadaljevanju: »MEDEX« in »družba MEDEX«) uporabljamo
                    piškotke, skladno z vsakokrat veljavnim Zakonom o
                    elektronskih komunikacijah (v nadaljevanju: »ZEKom-2«),
                    Uredbo (EU) 2016/679 Evropskega parlamenta in Sveta z dne
                    27. 4. 2016 o varstvu posameznikov pri obdelavi osebnih
                    podatkov in o prostem pretoku takih podatkov ter o
                    razveljavitvi Direktive 95/46/ES (v nadaljevanju: »Splošna
                    uredba o varstvu osebnih podatkov«) kot tudi vsakokrat
                    veljavnim Zakonom o varstvu osebnih podatkov (v
                    nadaljevanju: »ZVOP-2«).
                  </span>
                </p>
                <h2>
                  <strong>1. Kaj so piškotki?</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Piškotki so majhne tekstovne datoteke, ki se ob vašem prvem
                    obisku spletne strani shranijo na vašem računalniku oz.
                    drugi elektronski napravi in običajno vsebujejo:
                  </span>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      ime strežnika, s katerega je bil piškotek poslan,
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      življenjsko dobo piškotka,
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      vrednost (običajno gre za naključno generirano edinstveno
                      številko).
                    </span>
                  </li>
                </ul>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Piškotki se uporabljajo z namenom izboljšanja vaše
                    uporabniške izkušnje, spremljanja in analize vašega obiska
                    spletne strani, na podlagi česar so spletne vsebine
                    prilagojene uporabnikovim željam in optimizaciji uporabe
                    spletne strani. Služijo tudi pripravi anonimnih statističnih
                    podatkov, ki nam pomagajo razumeti uporabnikovo uporabo
                    spletne strani in tako pripomorejo k izboljšavi vsebine
                    spletne strani.
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Shranjevanje piškotkov je pod popolnim nadzorom brskalnika,
                    ki ga ima uporabnik – ta lahko shranjevanje piškotkov po
                    želji omeji ali onemogoči. Vsi piškotki, z izjemo nujnih, se
                    naložijo na vaš spletni brskalnik le na podlagi vaše izrecne
                    privolitve.
                  </span>
                </p>
                <h2>
                  <strong>2. Uporaba piškotkov tretjih oseb</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    V nekaterih primerih uporabljamo tudi piškotke tretjih oseb,
                    ki se lahko shranjujejo v okviru uporabe naše spletne
                    strani. Ti piškotki služijo za nemoteno uporabo različnih
                    možnosti, ki jih ponuja spletna stran, in uporabnikom
                    zagotavljajo enostaven dostop do vsebin.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    Konkretno na spletnih straneh MEDEX uporabljamo storitve
                    Google Analytics, pri katerih gre za eno najpogostejših in
                    obenem najzanesljivejših analitičnih orodij v internetu in
                    ki nam pomagajo pri razumevanju, kako stranke uporabljate
                    naše spletne strani in načine, kako lahko izboljšamo vašo
                    spletno izkušnjo. Več o Google Analytics piškotkih, kako jih
                    upravljate oz. onemogočite njihov hrambo, izveste tukaj
                    (povezava do{" "}
                  </span>
                  <a href="https://tools.google.com/dlpage/gaoptout">
                    <span style={{ fontWeight: 400 }}>
                      https://tools.google.com/dlpage/gaoptout
                    </span>
                  </a>
                  <span style={{ fontWeight: 400 }}>).</span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Nadalje na spletnih straneh MEDEX uporabljamo funkcijo
                    Remarketing, ki jo ponuja Google Inc. (v nadaljevanju:
                    »Google«), in ki nam omogoča, da preusmerimo uporabnike, ki
                    so na ciljno usmerjen način obiskali našo spletno mesto prek
                    natančnega oglaševanja na straneh Googlovega partnerskega
                    omrežja. Osebne oglase, ki temeljijo na zanimanjih, se lahko
                    nato prikažejo na drugih spletnih mestih, ki jih lahko
                    uporabnik obišče v Google Prikaznem omrežju. V zvezi s tem
                    lahko Googlu trajno onemogočite uporabo piškotkov s klikom
                    na naslednjo povezavo ter s prenosom in namestitvijo
                    razpoložljivega vtičnika:
                    https://www.google.com/settings/ads/plugin. Podatke o vrsti
                    piškotkov in o tem, katere druge tehnologije uporablja
                    Google, kot tudi informacije o zaščiti zasebnosti
                    uporabnikov najdete v Googlovih pravilih o zasebnosti na{" "}
                  </span>
                  <a href="https://www.google.com/privacy/ads/">
                    <span style={{ fontWeight: 400 }}>
                      http://www.google.com/privacy/ads/
                    </span>
                  </a>
                </p>
                <h2>
                  <strong>3. Začasni in trajni piškotki</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Začasni (ali sejni) piškotki prenehajo delovati v trenutku,
                    ko zaprete spletni brskalnik, v katerem ste si ogledali
                    spletno stran. S pomočjo teh piškotkov strani shranjujejo
                    začasne podatke.
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Trajni piškotki so tisti piškotki, ki se shranijo na vašem
                    računalniku in ostanejo na vašem računalniku tudi po tem, ko
                    ste zaprli spletni brskalnik. Z uporabo teh piškotkov
                    spletne strani hranijo podatke, da bi bila uporaba spletne
                    strani ob naslednjem obisku enostavna, vse funkcionalnosti
                    strani delujoče in uporabniška izkušnja optimalna.
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Čas trajanja piškotkov je naveden pri posameznem piškotku v
                    seznamu piškotkov, ki jih uporabljajo spletne strani MEDEX
                    spodaj.
                  </span>
                </p>
                <h2>
                  <strong>4. Piškotki, ki jih uporablja MEDEX</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Spletna stran MEDEX za svoje delovanje uporablja obvezne
                    piškotke, izvedbene in funkcionalne piškotke ter
                    oglaševalske piškotke.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Obvezni piškotki so tisti, ki so nujno potrebni za nemoteno
                    delovanje spletne strani{" "}
                  </span>
                  <a href="https://help-a-bee.com/">
                    <span style={{ fontWeight: 400 }}>
                      https://help-a-bee.com/
                    </span>
                  </a>
                  <span style={{ fontWeight: 400 }}>
                    . To so piškotki, ki se na vaš računalnik oz. drugo
                    elektronsko napravo vedno naložijo samodejno in za njihovo
                    namestitev vaše soglasje, skladno z drugim odstavkom 225.
                    člena ZEKom-2, ni potrebno.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Za uporabo izvedbenih in funkcionalnih ter oglaševalskih
                    piškotkov pa, skladno s prvim odstavkom 225. člena ZEKom-2,
                    potrebujemo vaše soglasje, zato v nadaljevanju pojasnjujemo
                    namen obdelave vaših podatkov, pri čemer je upravljalec teh
                    podatkov družba MEDEX.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Izvedbene in funkcionalne piškotke uporabljamo z namenom
                    spremljanja in analiziranja aktivnosti uporabnikov spletne
                    strani za izboljšanje funkcionalnosti spletenih strani oz.
                    naših storitev, kot na primer optimizacijo spletne strani za
                    različne vrste naprav, operacijskih sistemov, ugotavljanje
                    najbolj in najmanj popularnih izdelkov, analiziranja
                    prehajanja kupcev po spletni strani ipd. Konkretneje, nam ti
                    piškotki pomagajo, da si spletno mesto zapomni vaše izbrane
                    možnosti, v pomoč so nam pri povezovanju z vašimi socialnimi
                    omrežji in pomagajo nam ugotoviti, katere MEDEX izdelke na
                    spletišču bi vam lahko priporočili po ugodnih cenah. V
                    primeru odjave od teh piškotkov se bo delovanje brskanja po
                    spletišču lahko upočasnilo, oglasna sporočila ne bodo
                    ustrezna hkrati pa se ne boste mogli učinkovito povezovati z
                    vašimi socialnimi omrežji Facebook, Twitter ipd.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Oglaševalski piškotki uporabljamo z namenom omogočanja
                    boljšega prilagajanja oglaševanja vašim interesom tako na
                    naših spletiščih, kot na spletiščih izven MEDEX. Pridobljeni
                    podatki so anonimni, kar pomeni, da niso pridobljeni v
                    kakršnikoli povezavi z vašimi osebnimi podatki, ki ste jih
                    morda zaupali na našem spletišču. Z odjavo od teh piškotkov,
                    oglasna sporočila za vas ne bodo ustrezna vašim preferencam.
                  </span>
                </p>
                <p>&nbsp;</p>
                <h2>
                  <strong>Merjenje obiskov</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Ta spletna stran uporablja servise za merjenje števila
                    uporabnikov, in sicer Google Analytics, Plausible in
                    Facebook. Na podlagi tako zbranih podatkov ni mogoča
                    identifikacija uporabnikov, zbrani podatki pa se uporabljajo
                    izključno za statistične namene. Če želite preprečiti, da
                    vam servisi shranjujejo piškotke, jih lahko zavrnete na
                    naslednjih povezavah:
                  </span>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>Google Analytics – </span>
                    <a href="https://tools.google.com/dlpage/gaoptout">
                      <span style={{ fontWeight: 400 }}>
                        https://tools.google.com/dlpage/gaoptout
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>.</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Plausible – ne shranjuje piškotkov.
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Facebook - Uporablja ga Facebook za pripravo targetiranih
                      oglasov
                    </span>
                  </li>
                </ul>
                <p>
                  <br />
                  <br />
                </p>
                <h3>
                  <strong>4.1. Obvezni piškotki</strong>
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Ime piškotka</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Ponudnik piškotka
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Namen uporabe</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Veljavnost</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Izdajatelj</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_gid</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Statistika obiska spletne strani, pri čemer se
                            podatki obdelujejo anonimno. Podatki niso na voljo
                            tretjim osebam.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Do zaključka seje
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_gat</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Uporablja se za optimizacijo zahtevkov do
                            analitičnega orodja Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>1 dan</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_ga</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Statistika obiska spletne strani, pri čemer se
                            podatki obdelujejo anonimno. Podatki niso na voljo
                            tretjim osebam.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 leti</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <h3>
                  <strong>4.2. Ciljni ali oglaševalski piškotki</strong>
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Ime piškotka</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Ponudnik</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Namen uporabe</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Veljavnost</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Izdajatelj</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_fbp</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Facebook</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Uporablja ga Facebook za pripravo targetiranih
                            oglasov.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>3 mesece</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Facebook)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_fbc</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Facebook</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>&nbsp;</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 leti</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Facebook)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__kla_id</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Klaviyo</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Beleži klike iz Klaviyo maila na spletno stran.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 leti</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Klaviyo</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            KL_FORMS_MODAL
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Klaviyo</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Sproži se ob vpisu na novice.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 leti</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Klaviyo</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <h3>
                  <strong>4.3. Izvedbeni ali funkcionalni piškotki</strong>
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Ime piškotka</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Ponudnik</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Namen uporabe</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Veljavnost</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Izdajatelj</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__utma</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Uporablja za razlikovanje med uporabnikom in sejo.
                            Piškotek je ustvarjen med izvajanjem javascript
                            knjižnice, ko ni obstoječih __utma piškotkov v
                            obstoju.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 leti</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__utmb</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Uporablja se za določitev nove seje/obiska. Piškotek
                            je ustvarjen med izvajanjem javascript knjižnice, ko
                            ni obstoječih __utmb ali __utmc piškotkov v obstoju.
                            Piškotek je posodobljen vsakič, ko podatki poslani
                            Google Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Do zaključka seje
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__utmc</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Uporablja se za določitev nove seje/obiska. Piškotek
                            je ustvarjen med izvajanjem javascript knjižnice, ko
                            ni obstoječih __utmb ali __utmc piškotkov v obstoju.
                            Piškotek je posodobljen vsakič, ko podatki poslani
                            Google Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>30 minut</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Zunanji</span>
                        </p>
                        <p>
                          <span style={{ fontWeight: 400 }}>(Google.com)</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__utmz</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Shranjuje vir prometa ali oglaševalsko akcijo, ki
                            opredeljuje kako je uporabnik prišel na stran.
                            Piškotek je posodobljen vsakič, ko podatki poslani
                            Google Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>6 mesecev</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__utmv</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Uporabljen za shranjevanje variabilnih podatkov na
                            ravni obiskovalca. Piškotek je ustvarjen, če
                            razvijalec uporabi _setCustomVar metodo z poljubno
                            variablo na ravni obiskovalca. Piškotek je tudi
                            uporabljen pri _setVar metodi. Piškotek je
                            posodobljen vsakič, ko so podatki poslani Google
                            Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 leti</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            medex_*[Analytics]
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Uporablja se za beleženje nekaterih dogodkov, ki se
                            pošiljajo na Google Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Do zaključka seje
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Zunanji (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <h2>
                  <strong>5. Nastavitve piškotkov</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Piškotke, ki niso nujni za delovanje spletne strani MEDEX,
                    lahko onemogočite in s tem ne dovoljujete njihovega
                    shranjevanje na vašem računalniku oz. drugi elektronski
                    napravi. Če piškotke onemogočite, na spletni strani{" "}
                  </span>
                  <a href="https://help-a-bee.com/">
                    <span style={{ fontWeight: 400 }}>
                      https://help-a-bee.com/
                    </span>
                  </a>
                  <span style={{ fontWeight: 400 }}>
                    . ne bodo delovale določene funkcionalnosti (zlasti
                    pomnjenje izdelkov v košarici, seznam nedavno primerjanih
                    oz. ogledanih izdelkov ipd.).
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Nastavitve za piškotke lahko nadzirate in spreminjate tudi v
                    vašem spletnem brskalniku. Za informacije o nastavitvah za
                    piškotke izberite spletni brskalnik, ki ga uporabljate.
                  </span>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Internet Explorer 9 (povezava na{" "}
                    </span>
                    <a href="https://support.microsoft.com/sl-si/windows">
                      <span style={{ fontWeight: 400 }}>
                        https://support.microsoft.com/sl-si/windows
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Internet Explorer 7 in 8 (povezava na{" "}
                    </span>
                    <a href="https://support.microsoft.com/sl-si/windows/brisanje-in-upravljanje-pi%C5%A1kotkov-168dab11-0753-043d-7c16-ede5947fc64d">
                      <span style={{ fontWeight: 400 }}>
                        https://support.microsoft.com/sl-si/windows/brisanje-in-upravljanje-pi%C5%A1kotkov-168dab11-0753-043d-7c16-ede5947fc64d
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Google Chrome (povezava na{" "}
                    </span>
                    <a href="https://support.google.com/chrome/answer/95647?hl=sl-sl&p=cpn_cookies">
                      <span style={{ fontWeight: 400 }}>
                        https://support.google.com/chrome/answer/95647?hl=sl-sl&amp;p=cpn_cookies
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Mozilla Firefox (povezava na{" "}
                    </span>
                    <a href="https://support.mozilla.org/sl/kb/piskotki">
                      <span style={{ fontWeight: 400 }}>
                        https://support.mozilla.org/sl/kb/piskotki
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Opera (stran v angleškem jeziku) (povezava na{" "}
                    </span>
                    <a href="https://help.opera.com/en/latest/web-preferences/">
                      <span style={{ fontWeight: 400 }}>
                        https://help.opera.com/en/latest/web-preferences/
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Safari (stran v angleškem jeziku) (povezava na{" "}
                    </span>
                    <a href="https://support.apple.com/sl-si/guide/safari/ibrw850f6c51/mac">
                      <span style={{ fontWeight: 400 }}>
                        https://support.apple.com/sl-si/guide/safari/ibrw850f6c51/mac
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                </ul>
                <p>&nbsp;</p>
              </>
            )}

            {currentLanguage === "en" && (
              <>
                <h1>
                  <strong>ABOUT COOKIES</strong>
                </h1>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    On the websites of the company MEDEX TRADE d.o.o.
                    (hereinafter: "MEDEX" and "MEDEX company") use cookies in
                    accordance with the Electronic Communications Act
                    (hereinafter: "ZEKom-2"), Regulation (EU) 2016/679 of the
                    European Parliament and of the Council of 27 4. 2016 on the
                    protection of individuals in the processing of personal data
                    and on the free flow of such data and on the repeal of
                    Directive 95/46/EC (hereinafter: "General Regulation on the
                    Protection of Personal Data") as well as the Act on the
                    Protection of Personal Data (hereinafter : "ZVOP-2").
                  </span>
                </p>
                <h2>
                  <strong>1. What are cookies?</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Cookies are small text files that are stored on your
                    computer when you first visit the website. other electronic
                    devices and usually contain:
                  </span>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      the name of the server from which the cookie was sent,
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      lifetime of the cookie,
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      value (usually a randomly generated unique number).
                    </span>
                  </li>
                </ul>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Cookies are used for the purpose of improving your user
                    experience, monitoring and analyzing your visit to the
                    website, on the basis of which the website content is
                    adapted to the user's wishes and optimization of the use of
                    the website. They also serve to prepare anonymous
                    statistical data that help us understand the user's use of
                    the website and thus help to improve the content of the
                    website.
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The storage of cookies is under the full control of the
                    user's browser - it can limit or disable the storage of
                    cookies as desired. All cookies, with the exception of
                    essential cookies, are loaded on your web browser only based
                    on your express consent.
                  </span>
                </p>
                <h2>
                  <strong>2. Use of third-party cookies</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    In some cases, we also use third-party cookies that may be
                    stored as part of the use of our website. These cookies
                    serve for the smooth use of various options offered by the
                    website and provide users with easy access to content.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    Specifically, on the MEDEX website, we use Google Analytics
                    services, which are one of the most common and at the same
                    time the most reliable analytical tools on the Internet and
                    which help us understand how customers use our website and
                    the ways in which we can improve your online experience.
                    More about Google Analytics cookies, how to manage them or
                    disable their storage, find out here (link to{" "}
                  </span>
                  <a href="https://tools.google.com/dlpage/gaoptout">
                    <span style={{ fontWeight: 400 }}>
                      https://tools.google.com/dlpage/gaoptout
                    </span>
                  </a>
                  <span style={{ fontWeight: 400 }}>).</span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Furthermore, on the MEDEX website, we use the Remarketing
                    function offered by Google Inc. (hereinafter: "Google"), and
                    which allows us to redirect users who have visited our
                    website in a targeted manner through precise advertising on
                    the pages of the Google partner network. Personalized,
                    interest-based ads may then appear on other websites the
                    user may visit on the Google Display Network. In this
                    regard, you can permanently disable Google's use of cookies
                    by clicking on the following link and by downloading and
                    installing the available plugin:
                    https://www.google.com/settings/ads/plugin. Information on
                    the type of cookies and which other technologies Google
                    uses, as well as information on the protection of user
                    privacy, can be found in Google's privacy policy at
                  </span>
                  <a href="https://www.google.com/privacy/ads/">
                    <span style={{ fontWeight: 400 }}>
                      http://www.google.com/privacy/ads/
                    </span>
                  </a>
                </p>
                <h2>
                  <strong>3. Temporary and permanent cookies</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Temporary (or session) cookies stop working the moment you
                    close the web browser in which you viewed the website. With
                    the help of these cookies, the site stores temporary data.
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Persistent cookies are those cookies that are stored on your
                    computer and remain on your computer even after you have
                    closed your web browser. By using these cookies, websites
                    store data so that using the website on the next visit is
                    easy, all the functionalities of the website are working and
                    the user experience is optimal.
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The duration of cookies is indicated for each cookie in the
                    list of cookies used by MEDEX websites below.
                  </span>
                </p>
                <h2>
                  <strong>4. Cookies used by MEDEX</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The MEDEX website uses mandatory cookies, execution and
                    functional cookies and advertising cookies for its
                    operation.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Mandatory cookies are those that are absolutely necessary
                    for the smooth operation of the website{" "}
                  </span>
                  <a href="https://help-a-bee.com/">
                    <span style={{ fontWeight: 400 }}>
                      https://help-a-bee.com/
                    </span>
                  </a>
                  <span style={{ fontWeight: 400 }}>
                    . These are cookies that are placed on your computer or the
                    other electronic device is always loaded automatically and
                    your consent, in accordance with the second paragraph of
                    Article 225 of ZEKom-2, is not required for their
                    installation.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    In accordance with the first paragraph of Article 225 of
                    ZEKom-2, we need your consent for the use of executive and
                    functional and advertising cookies, so below we explain the
                    purpose of processing your data, whereby the controller of
                    this data is the company MEDEX.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    We use performance and functional cookies for the purpose of
                    monitoring and analyzing the activities of website users to
                    improve the functionality of the website or of our services,
                    such as optimizing the website for different types of
                    devices and operating systems, determining the most and
                    least popular products, analyzing customer traffic on the
                    website, etc. More specifically, these cookies help the
                    website remember your choices, help us connect with your
                    social networks and help us determine which MEDEX products
                    on the website we can recommend to you at good prices. If
                    you opt out of these cookies, browsing the website may slow
                    down, advertising messages will not be relevant, and at the
                    same time you will not be able to effectively connect to
                    your social networks Facebook, Twitter, etc.
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    <br />
                    <br />
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    We use advertising cookies for the purpose of enabling
                    better adaptation of advertising to your interests both on
                    our websites and on websites outside of MEDEX. The obtained
                    data is anonymous, which means that it is not obtained in
                    any connection with your personal data that you may have
                    trusted on our website. By opting out of these cookies,
                    advertising messages to you will not be tailored to your
                    preferences.
                  </span>
                </p>
                <p>&nbsp;</p>
                <h2>
                  <strong>Measuring visits</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    This website uses services to measure the number of users,
                    namely Google Analytics, Plausible and Facebook. On the
                    basis of the data collected in this way, it is not possible
                    to identify users, and the collected data is used
                    exclusively for statistical purposes. If you want to prevent
                    the services from storing cookies, you can refuse them at
                    the following links:
                  </span>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>Google Analytics – </span>
                    <a href="https://tools.google.com/dlpage/gaoptout">
                      <span style={{ fontWeight: 400 }}>
                        https://tools.google.com/dlpage/gaoptout
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>.</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Plausible - does not store cookies.
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Facebook - Used by Facebook to prepare targeted ads
                    </span>
                  </li>
                </ul>
                <p>
                  <br />
                  <br />
                </p>
                <h3>
                  <strong>4.1. Mandatory cookies</strong>
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            The name of the cookie
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Cookie provider
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Purpose of use
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Validity</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Issuer</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_gid</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Website visit statistics, where the data is
                            processed anonymously. The data is not available to
                            third parties.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Sows to the conclusion
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_hole</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            It is used to optimize requests to the analytical
                            tool Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>1 and</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_ga</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Website visit statistics, where the data is
                            processed anonymously. The data is not available to
                            third parties.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 years</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <br />
                  <br />
                  <br />
                  <br />
                </p>
                <h3>
                  <strong>4.2. Targeting or advertising cookies</strong>
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            The name of the cookie
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Provider</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Purpose of use
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Validity</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Issuer</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_fbp</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Facebook</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            It is used by Facebook to prepare targeted
                            advertisements.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>3 months</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Facebook)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>_fbc</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Facebook</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>&nbsp;</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 years</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Facebook)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__clad_id</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Klaviyo</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Records clicks from Klaviyo mail to the website.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 years</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Klaviyo</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            KL_FORMS_MODAL
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Klaviyo</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            It is triggered when you subscribe to the
                            newsletter.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 years</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Klaviyo</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <h3>
                  <strong>4.3. Performance or functional cookies</strong>
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            The name of the cookie
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Provider</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Purpose of use
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Validity</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>Issuer</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__don't win</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Used to distinguish between a user and a session.
                            The cookie is created during the execution of the
                            javascript library when there are no existing __utma
                            cookies in existence.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 years</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__utmb</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <h1>EN</h1>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Used to define a new session/visit. The cookie is
                            created during the execution of the javascript
                            library when there are no existing __utmb or __utmc
                            cookies in existence. The cookie is updated every
                            time the data is sent to Google Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Sows to the conclusion
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__utmc</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Used to define a new session/visit. The cookie is
                            created during the execution of the javascript
                            library when there are no existing __utmb or __utmc
                            cookies in existence. The cookie is updated every
                            time the data is sent to Google Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>30 minutes</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>External</span>
                        </p>
                        <p>
                          <span style={{ fontWeight: 400 }}>(Google.com)</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__utmz</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            It stores the traffic source or advertising campaign
                            that defines how the user got to the page. The
                            cookie is updated every time the data is sent to
                            Google Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>6 months</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>__utmv</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Used to store visitor-level variable data. A cookie
                            is created when a developer uses the _setCustomVar
                            method with an arbitrary visitor-level variable. A
                            cookie is also used in the _setVar method. The
                            cookie is updated each time data is sent to Google
                            Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>2 years</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            medex_*[Analytics]
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Google Analytics
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            It is used to log certain events that are sent to
                            Google Analytics.
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Sows to the conclusion
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            External (Google.com)
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <h2>
                  <strong>5. Cookie settings</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    You can disable cookies that are not necessary for the
                    operation of the MEDEX website and thereby prevent their
                    storage on your computer or other electronic devices. If you
                    disable cookies, on the website
                  </span>
                  <a href="https://help-a-bee.com/">
                    <span style={{ fontWeight: 400 }}>
                      https://help-a-bee.com/
                    </span>
                  </a>
                  <span style={{ fontWeight: 400 }}>
                    . certain functionalities will not work (in particular,
                    remembering products in the basket, the list of recently
                    compared or viewed products, etc.).
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Cookie settings can also be controlled and changed in your
                    web browser. For information about cookie settings, select
                    the web browser you are using.
                  </span>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Internet Explorer 9 (link to
                    </span>
                    <a href="https://support.microsoft.com/sl-si/windows">
                      <span style={{ fontWeight: 400 }}>
                        https://support.microsoft.com/sl-si/windows
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Internet Explorer 7 and 8 (link to
                    </span>
                    <a href="https://support.microsoft.com/sl-si/windows/brisanje-in-upravljanje-pi%C5%A1kotkov-168dab11-0753-043d-7c16-ede5947fc64d">
                      <span style={{ fontWeight: 400 }}>
                        https://support.microsoft.com/sl-si/windows/brisanje-in-upravljanje-pi%C5%A1kotkov-168dab11-0753-043d-7c16-ede5947fc64d
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Google Chrome (link to
                    </span>
                    <a href="https://support.google.com/chrome/answer/95647?hl=sl-sl&p=cpn_cookies">
                      <span style={{ fontWeight: 400 }}>
                        https://support.google.com/chrome/answer/95647?hl=sl-sl&amp;p=cpn_cookies
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Mozilla Firefox (link to
                    </span>
                    <a href="https://support.mozilla.org/sl/kb/piskotki">
                      <span style={{ fontWeight: 400 }}>
                        https://support.mozilla.org/sl/kb/piskotki
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Opera (English language site) (link to
                    </span>
                    <a href="https://help.opera.com/en/latest/web-preferences/">
                      <span style={{ fontWeight: 400 }}>
                        https://help.opera.com/en/latest/web-preferences/
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Safari (English site) (link to
                    </span>
                    <a href="https://support.apple.com/sl-si/guide/safari/ibrw850f6c51/mac">
                      <span style={{ fontWeight: 400 }}>
                        https://support.apple.com/sl-si/guide/safari/ibrw850f6c51/mac
                      </span>
                    </a>
                    <span style={{ fontWeight: 400 }}>)</span>
                  </li>
                </ul>
                <p>&nbsp;</p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-100 text-center">
        <a
          className="btn btn-yellow text-uppercase text-white px-5 mt-3"
          href={"/?lang=" + currentLanguage}>
          {t("Back")}
        </a>
      </div>
    </motion.div>
  );
};

export default Cookies;
