import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { fadeInOut } from "../../utils/animations";

import BG from "../../assets/images/BG-static.png";

import imgMocCebel from "../../assets/images/pages/medex_moc_cebel.jpg";
import imgBoljsiSvet from "../../assets/images/pages/boljsi_svet_vseh.jpg";
import imgTrajnostni from "../../assets/images/pages/trajnostni_razvoj.jpg";
import imgBanglades from "../../assets/images/pages/banglades.jpg";
import imgSlovenskaOprema from "../../assets/images/pages/slovenska oprema.jpg";
import imgAmbasadorGosp from "../../assets/images/pages/ambasador_gospodarstva.jpg";
import imgAmbasadorCebel from "../../assets/images/pages/ambasador_cebel.jpg";

const CebeljiIzdelki = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="pages pb-5"
      style={{ backgroundImage: `url(${BG})` }}>
      <div className="container-xl mt-6">
        <div className="wrapper variant-two rounded">
          <h1 className="display-5 text-yellow mb-5">{t("AboutMedex")}</h1>
          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7">
              <h2>{t("PageOMedexu1H")}</h2>

              <p>{t("PageOMedexu1P")}</p>
            </div>
            <div className="col-md-5">
              <img className="w-100" src={imgMocCebel} />
            </div>
          </div>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7 order-md-2">
              <h2>{t("PageOMedexu2H")}</h2>

              <p>{t("PageOMedexu2P")}</p>
            </div>
            <div className="col-md-5 order-md-1">
              <img className="w-100" src={imgBoljsiSvet} />
            </div>
          </div>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7">
              <p>{t("PageOMedexu3P")}</p>
            </div>
            <div className="col-md-5">
              <img className="w-100" src={imgTrajnostni} />
            </div>
          </div>

          <h1 className="display-5 text-green mb-5">{t("PageOMedexuH2")}</h1>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7 order-md-2">
              <h2>{t("PageOMedexu4H")}</h2>

              <p>{t("PageOMedexu4P")}</p>
            </div>
            <div className="col-md-5 order-md-1">
              <img className="w-100" src={imgBanglades} />
            </div>
          </div>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7">
              <h2>{t("PageOMedexu5H")}</h2>
              <p>{t("PageOMedexu5P")}</p>

              <div className="button-absolute d-none">
                <a
                  className="btn btn-green text-uppercase text-white px-5"
                  href="https://www.medex.si/spletna-trgovina#sort-direction=desc;sort=recommended;categories=52"
                  target="_blank">
                  {t("CTA")}
                </a>
              </div>
            </div>
            <div className="col-md-5">
              <img className="w-100" src={imgTrajnostni} />
            </div>
          </div>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7 order-md-2">
              <h2>{t("PageOMedexu6H")}</h2>

              <p>{t("PageOMedexu6P")}</p>
            </div>
            <div className="col-md-5 order-md-1">
              <img className="w-100" src={imgSlovenskaOprema} />
            </div>
          </div>
          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7">
              <h2>{t("PageOMedexu7H")}</h2>

              <p>{t("PageOMedexu7P")}</p>
            </div>
            <div className="col-md-5">
              <a
                href="https://www.youtube.com/watch?v=N20ZPsnHGSc&t=1s"
                target="_blank">
                <img className="w-100" src={imgAmbasadorGosp} />
              </a>
            </div>
          </div>
          <h1 className="display-5 text-yellow mb-5">{t("PageOMedexuH3")}</h1>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7 order-md-2">
              <h2>{t("PageOMedexu8H")}</h2>

              <p>{t("PageOMedexu8P")}</p>
            </div>
            <div className="col-md-5 order-md-1">
              <img className="w-100" src={imgAmbasadorCebel} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 text-center">
        <a
          className="btn btn-yellow text-uppercase text-white px-5 mt-3"
          href={"/?lang=" + currentLanguage}>
          {t("Back")}
        </a>
      </div>
    </motion.div>
  );
};

export default CebeljiIzdelki;
