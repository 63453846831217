import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { fadeInOut } from "../../utils/animations";

import BG from "../../assets/images/BG-static.png";
import Icon from "../../assets/images/icon-cebelca.svg";

const CebeljiIzdelki = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="pages pb-5"
      style={{ backgroundImage: `url(${BG})` }}>
      <div className="container-xl mt-6">
        <div className="wrapper variant-two rounded">
          <h1 className="display-5 text-yellow mb-2 text-center">
            {t("PagePomagajTitle")}
          </h1>
          <h2 className="text-center">
            {t(
              "Kako lahko poleg sajenja medovitih dreves še pripomoremo k čebelam prijaznemu svetu?"
            )}
          </h2>

          <div className="entry entry__icons row g-md-5 mt-3 position-relative text-center text-md-start">
            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj1H")}
              </h3>
              <p>{t("PagePomagaj1P")}</p>
            </div>
            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj2H")}
              </h3>
              <p>{t("PagePomagaj2P")}</p>
            </div>
            <div className="col-md-4 ">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj3H")}
              </h3>
              <p>{t("PagePomagaj3P")}</p>
            </div>
            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj4H")}
              </h3>
              <p>{t("PagePomagaj4P")}</p>
            </div>
            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj5H")}
              </h3>
              <p>{t("PagePomagaj5P")}</p>
            </div>
            <div className="col-md-4 ">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj6H")}
              </h3>
              <p>{t("PagePomagaj6P")}</p>
            </div>
            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj7H")}
              </h3>
              <p>{t("PagePomagaj7P")}</p>
            </div>
            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj8H")}
              </h3>
              <p>{t("PagePomagaj8P")}</p>
            </div>
            <div className="col-md-4 ">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj9H")}
              </h3>
              <p>{t("PagePomagaj9P")}</p>
            </div>
            <div className="col-12">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PagePomagaj10H")}
              </h3>
              <p>{t("PagePomagaj10P")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 text-center">
        <a
          className="btn btn-yellow text-uppercase text-white px-5 mt-3"
          href={"/?lang=" + currentLanguage}>
          {t("Back")}
        </a>
      </div>
    </motion.div>
  );
};

export default CebeljiIzdelki;
