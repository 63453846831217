import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ProgressSVG = ({
  progressWidth,
  beePosition,
  textPosition,
  numDrags,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <svg
        className="svg-progress"
        id="svgProgress"
        width="855"
        viewBox="0 0 855 158"
        pointerEvents="bounding-box"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Group 187">
          <g id="White">
            <g id="Vector" filter="url(#filter0_d_147_6)">
              <path
                d="M55.5016 70.628C84.28 68.7982 112.061 63.5714 140.839 61.69C170.165 64.4489 199.364 68.964 228.906 70.5852C239.776 69.9111 250.661 70.0629 261.443 68.8219C266.131 68.2599 270.736 67.4406 275.258 66.364C312.647 55.262 356.532 57.8786 393.349 68.4103C401.199 69.624 409.121 70.5274 417.187 70.655C425.406 70.5747 433.788 70.9057 441.913 69.8972C450.505 68.7809 458.528 66.3796 467.282 65.5714C509.821 53.4899 550.323 73.535 592.909 73.4285C621.169 73.3072 646.834 63.3521 675.09 63.0244C675.64 63.1222 676.969 63.006 677.358 62.899C697.853 71.1641 721.893 76.091 746.218 74.7693C771.046 70.8617 798.526 70.9029 821.651 63.5019C839.909 55.3227 836.821 34.8604 816.373 29.3822C781.33 21.8787 742.821 45.38 709.819 29.0298C706.117 27.6723 702.185 26.6783 698.253 25.6328C691.96 23.836 685.063 23.3872 678.249 23.1958C639.065 20.8429 602.301 41.0753 563.912 30.146C553.249 29.2179 543.336 26.3725 533.126 24.4075C525.274 23.039 517.055 23.1193 509.061 22.6296C498.096 22.3238 487.22 22.6882 476.434 23.6711C448.479 27.9189 418.581 37.554 390.997 26.7773C382.126 25.3154 373.329 23.5948 364.307 22.4957C327.377 19.1401 287.949 20.8159 253.52 31.0631C244.305 32.392 234.746 31.9176 225.443 32.6795C197.98 29.0249 169.833 26.5126 141.997 23.9456C121.117 25.2337 99.9211 26.4216 79.7932 30.5413C64.1674 32.3974 46.6086 31.072 32.6141 36.7836C6.56716 47.3102 26.242 76.3855 55.5016 70.628Z"
                fill="white"
              />
            </g>
          </g>
          <g id="Yellow">
            <g id="Vector_2" filter="url(#filter1_d_147_6)">
              <path
                d="M55.5016 70.628C84.28 68.7982 112.061 63.5714 140.839 61.69C170.165 64.4489 199.364 68.964 228.906 70.5852C239.776 69.9111 250.661 70.0629 261.443 68.8219C266.131 68.2599 270.736 67.4406 275.258 66.364C312.647 55.262 356.532 57.8786 393.349 68.4103C401.199 69.624 409.121 70.5274 417.187 70.655C425.406 70.5747 433.788 70.9057 441.913 69.8972C450.505 68.7809 458.528 66.3796 467.282 65.5714C509.821 53.4899 550.323 73.535 592.909 73.4285C621.169 73.3072 646.834 63.3521 675.09 63.0244C675.64 63.1222 676.969 63.006 677.358 62.899C697.853 71.1641 721.893 76.091 746.218 74.7693C771.046 70.8617 798.526 70.9029 821.651 63.5019C839.909 55.3227 836.821 34.8604 816.373 29.3822C781.33 21.8787 742.821 45.38 709.819 29.0298C706.117 27.6723 702.185 26.6783 698.253 25.6328C691.96 23.836 685.063 23.3872 678.249 23.1958C639.065 20.8429 602.301 41.0753 563.912 30.146C553.249 29.2179 543.336 26.3725 533.126 24.4075C525.274 23.039 517.055 23.1193 509.061 22.6296C498.096 22.3238 487.22 22.6882 476.434 23.6711C448.479 27.9189 418.581 37.554 390.997 26.7773C382.126 25.3154 373.329 23.5948 364.307 22.4957C327.377 19.1401 287.949 20.8159 253.52 31.0631C244.305 32.392 234.746 31.9176 225.443 32.6795C197.98 29.0249 169.833 26.5126 141.997 23.9456C121.117 25.2337 99.9211 26.4216 79.7932 30.5413C64.1674 32.3974 46.6086 31.072 32.6141 36.7836C6.56716 47.3102 26.242 76.3855 55.5016 70.628Z"
                fill="#F5A800"
              />
            </g>
          </g>
          <g id="bee" style={beePosition}>
            <path
              d="M45.4758 43.2737C46.9994 42.9598 48.9084 44.2845 48.6529 46.0005C48.3091 48.6346 48.2858 51.2299 48.6619 53.9066C49.117 56.7033 45.7448 57.8888 44.1981 56.3259C43.0368 56.4015 41.9964 56.3977 41.0024 55.6753C39.8086 54.9123 39.0608 53.512 39.1538 52.0749C39.1956 51.8754 39.2374 51.6759 39.321 51.2769C38.4478 50.4751 38.2202 49.0768 38.8706 47.9611C40.213 45.5304 42.6285 43.942 45.4758 43.2737Z"
              fill="#FFC713"
            />
            <path
              d="M26.4687 43.0441C26.5477 43.1642 26.6267 43.2842 26.5848 43.4837C28.4242 43.6502 30.3333 44.9749 31.16 46.4952C32.3444 48.2964 32.4837 50.6128 31.6243 52.7259C31.5825 52.9254 31.4617 53.0048 31.4199 53.2043C31.4989 53.3244 31.5778 53.4444 31.6568 53.5645C32.2467 54.7246 31.7496 56.5995 30.5047 57.0741C28.8186 57.6669 27.1325 58.2596 25.4463 58.8524C23.3189 59.5633 21.0523 57.9578 21.8327 55.7247C22.4505 53.7704 22.7479 51.8549 22.7665 49.7786C22.7758 48.7405 22.7062 47.5823 22.7945 46.6642C22.316 46.4628 21.8794 46.0619 21.6844 45.5022C20.2724 42.3027 24.936 39.924 26.4687 43.0441Z"
              fill="#FFC713"
            />
            <path
              d="M41.007 55.1564C43.009 55.0439 44.63 57.246 44.4534 59.0821C44.407 59.8007 44.1608 60.4786 43.7566 60.9163C43.5614 64.8287 41.3736 67.8154 37.7133 69.8783C34.1738 71.8618 29.2826 72.8421 26.6769 68.8795C24.3871 65.3973 24.076 59.9258 28.1776 57.7447C31.8379 55.6818 37.0031 55.3813 41.007 55.1564Z"
              fill="white"
            />
            <path
              d="M30.6775 28.9247C33.641 28.696 36.5533 29.7049 38.7782 31.5099C41.0031 33.3149 43.5391 36.1192 44.2729 39.0767C44.389 39.5163 44.4633 40.1555 44.3797 40.5545C44.6026 42.4719 43.5435 44.5443 41.5787 44.9764C40.6543 45.4122 39.651 45.728 38.6105 45.7241C35.8886 45.794 32.9762 44.7851 30.547 43.4586C28.0388 42.0119 25.1775 39.7654 24.4437 36.8079C23.9421 34.7298 24.5182 32.975 25.7305 31.6617C26.9058 30.0289 28.6755 29.0372 30.6775 28.9247Z"
              fill="white"
            />
            <path
              d="M26.6586 62.0115C24.7354 66.7161 27.6848 72.5167 34.5872 70.3858C39.3623 68.9658 44.3372 63.1144 43.4501 59.3977C42.4423 55.7605 36.794 56.3786 32.019 57.7986C30.5326 58.432 27.9267 58.9416 26.6586 62.0115ZM40.0642 53.1963C42.7025 53.5254 44.8949 54.4917 45.9956 56.6919C46.4324 52.6207 46.4325 48.1486 45.9496 43.9941C44.7697 46.146 42.6794 47.1765 40.0365 47.3665C40.1386 49.3633 40.1618 51.2401 40.0642 53.1963ZM32.0705 43.1447C36.7805 44.5196 42.4984 45.0596 43.4507 41.5094C44.5238 37.8797 39.4145 31.9514 34.7046 30.5766C27.8395 28.555 24.8665 34.2939 26.7057 38.9325C28.0387 42.0119 30.5563 42.4204 32.0705 43.1447ZM30.8813 46.3347C28.5264 45.6473 26.334 44.6809 25.0288 42.9592L24.8709 42.719C24.6896 45.0742 24.6663 47.6695 24.643 50.2649C24.6197 52.8602 24.6754 55.5756 24.8147 57.892L24.9772 57.6131C26.2732 55.9009 28.3635 54.8704 30.7696 54.3202C30.7418 52.9625 30.7929 51.7248 30.8858 50.2877C30.858 48.93 30.8302 47.5723 30.8813 46.3347ZM35.2003 73.4226C25.5294 76.342 21.2052 67.6615 23.3468 60.9213C18.6461 58.5083 15.5992 54.6639 15.5575 50.3913C15.5159 46.1187 18.6327 42.1771 23.2963 39.7984C21.1552 33.1224 25.5961 24.3542 35.2157 27.1445C40.404 28.7208 45.3182 34.0893 46.4746 39.0049C49.1454 40.1726 51.3332 41.658 52.917 43.5405C55.2906 42.1517 57.1858 40.5614 59.369 38.0938C60.4235 36.5404 63.2894 38.2679 61.7518 40.1389C59.6476 42.7266 57.1903 44.5145 54.6542 46.1822C55.1233 47.4217 55.5505 48.8608 55.5784 50.2185C55.6062 51.5762 55.2346 52.8525 54.7421 54.2083C57.3293 55.7751 59.8329 57.7408 61.8116 60.2236C63.3954 62.1061 60.5387 63.8126 59.3915 62.331C57.2131 59.8075 55.2251 58.3628 52.9166 56.9568C51.3791 58.8278 49.0844 60.3367 46.4321 61.5648C45.4286 66.3527 40.5373 71.8051 35.2003 73.4226Z"
              fill="#53210D"
            />
          </g>
          <g id="Progress">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <p className="mb-0 fw-bold">{t("ProgressMilestone6H")}</p>
                </Tooltip>
              }>
              <g className="milestone-icon" id="Milestone6">
                <path
                  id="Vector_3"
                  d="M823.916 117.219C824.017 118.525 824.117 119.732 824.117 119.732C824.117 119.732 824.418 118.525 824.619 117.219H824.519C824.218 117.219 824.117 117.219 823.916 117.219Z"
                  fill="url(#paint0_linear_147_6)"
                />
                <g id="Group">
                  <path
                    id="Vector_4"
                    d="M804.924 104.635C804.924 104.535 804.824 104.535 804.723 104.535C804.022 104.535 803.32 104.535 802.619 104.535C802.619 104.535 802.519 104.535 802.418 104.535C802.418 104.635 802.418 104.635 802.418 104.735C802.418 105.036 802.519 105.337 802.519 105.638C802.819 107.242 803.32 108.746 804.222 110.049C804.924 111.052 805.726 111.954 806.928 112.555C806.928 112.555 807.028 112.555 807.129 112.656C806.928 112.255 806.728 111.954 806.628 111.553C805.726 109.247 805.224 106.941 804.924 104.635Z"
                    fill="url(#paint1_linear_147_6)"
                  />
                  <path
                    id="Vector_5"
                    d="M820.057 104.435C819.957 104.435 819.957 104.535 819.957 104.535C819.856 105.136 819.756 105.738 819.656 106.239C819.355 107.944 818.854 109.648 818.153 111.252C817.952 111.653 817.852 111.954 817.652 112.355C817.752 112.255 817.952 112.255 818.052 112.155C819.155 111.453 819.957 110.55 820.558 109.448C821.36 108.144 821.761 106.741 822.061 105.237C822.061 104.936 822.161 104.635 822.161 104.435C822.161 104.435 822.161 104.435 822.061 104.435C821.56 104.435 820.758 104.435 820.057 104.435Z"
                    fill="url(#paint2_linear_147_6)"
                  />
                  <path
                    id="Vector_6"
                    d="M812.34 91L794 101.628V122.782L812.34 133.41L830.68 122.782V101.628L812.34 91ZM823.765 104.736C823.765 105.337 823.665 105.838 823.464 106.34C823.063 108.044 822.362 109.749 821.36 111.152C820.558 112.355 819.456 113.258 818.153 113.859C817.551 114.16 816.95 114.361 816.349 114.461H816.249C815.948 114.762 815.747 114.962 815.447 115.263C815.447 115.363 815.547 115.363 815.547 115.463C815.547 115.864 815.547 116.266 815.547 116.767C815.547 116.767 815.547 116.867 815.547 116.967C815.046 116.967 814.545 116.967 814.044 116.967C814.044 118.271 814.044 119.574 814.044 120.877C814.545 120.877 815.046 120.877 815.547 120.877C815.547 121.379 815.547 121.88 815.547 122.482C816.048 122.482 816.549 122.482 817.05 122.482C817.05 123.484 817.05 124.587 817.05 125.59C813.943 125.59 810.837 125.59 807.73 125.59C807.73 124.587 807.73 123.484 807.73 122.482C808.231 122.482 808.732 122.482 809.233 122.482C809.233 121.98 809.233 121.479 809.233 120.877C809.734 120.877 810.235 120.877 810.737 120.877C810.737 119.574 810.737 118.271 810.737 116.967C810.235 116.967 809.734 116.967 809.233 116.967C809.233 116.867 809.233 116.667 809.233 116.566C809.233 116.266 809.233 115.864 809.233 115.564C809.233 115.463 809.233 115.463 809.333 115.363C809.133 115.163 808.832 114.862 808.532 114.561C808.532 114.561 808.431 114.561 808.431 114.461C806.728 114.26 805.425 113.458 804.222 112.255C803.02 111.052 802.218 109.548 801.617 108.044C801.015 106.44 800.715 104.736 800.715 103.031V102.931C802.017 102.931 803.32 102.931 804.523 102.931C804.523 102.229 804.523 101.628 804.423 100.926C809.634 100.926 814.745 100.926 819.957 100.926C819.957 101.628 819.957 102.229 819.856 102.931C821.059 102.931 822.262 102.931 823.464 102.931C823.464 103.031 823.464 103.031 823.464 103.131C823.765 103.633 823.865 104.134 823.765 104.736Z"
                    fill="url(#paint3_linear_147_6)"
                  />
                </g>
              </g>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <p className="mb-0 fw-bold">{t("ProgressMilestone5H")}</p>
                </Tooltip>
              }>
              <g
                className="milestone-icon"
                id="Milestone5"
                filter="url(#filter2_d_147_6)">
                <path
                  id="Vector_7"
                  d="M658.249 117.15C658.349 118.454 658.45 119.657 658.45 119.657C658.45 119.657 658.75 118.454 658.951 117.15H658.851C658.55 117.15 658.45 117.15 658.249 117.15Z"
                  fill="url(#paint4_linear_147_6)"
                />
                <g id="Group_2">
                  <path
                    id="Vector_8"
                    d="M639.908 101.812V123.065L658.249 133.692L676.59 123.065V101.812L658.249 91.2856L639.908 101.812ZM664.162 99.9073C664.463 100.81 664.262 101.511 664.864 102.213C665.465 102.915 666.167 102.614 667.069 103.216C668.271 103.917 669.173 105.421 669.173 106.825C669.173 106.825 669.173 106.925 669.173 107.025C669.073 108.529 667.971 109.231 667.971 109.932C667.971 110.033 667.971 110.133 668.071 110.233C668.372 110.835 669.073 110.634 670.075 111.436C670.877 112.038 671.679 113.241 671.679 114.343C671.679 114.644 671.679 114.845 671.579 115.045C671.078 116.248 669.574 116.649 669.274 116.649C667.369 117.151 666.768 115.947 664.964 116.348C663.36 116.649 663.36 117.652 661.857 117.752C660.955 117.852 660.554 117.451 659.853 117.251C659.452 119.156 659.051 121.161 659.051 121.361C659.051 121.762 659.552 127.476 659.552 127.476C657.347 127.877 656.946 127.476 656.946 127.476L657.447 119.557C657.447 119.557 657.347 118.554 657.147 117.451C656.545 117.752 655.944 118.253 654.641 118.354C653.138 118.554 650.833 118.253 650.031 116.95C649.83 116.649 649.83 116.449 649.83 116.148C649.83 115.847 649.931 115.647 649.931 115.246C649.931 114.945 649.931 114.644 649.73 114.243C649.029 112.539 647.525 112.739 646.824 111.135C646.824 111.135 646.623 110.534 646.623 109.832C646.623 109.431 646.623 108.93 646.824 108.529C647.225 107.627 647.726 107.526 649.029 106.323C649.129 106.223 650.231 105.12 650.933 104.118C652.236 102.113 651.534 101.11 652.436 99.5063C653.739 97.2005 657.347 96.0977 660.254 96.8997C660.755 97.3007 663.461 98.0025 664.162 99.9073Z"
                    fill="url(#paint5_linear_147_6)"
                  />
                </g>
              </g>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <p className="mb-0 fw-bold">{t("ProgressMilestone4H")}</p>
                </Tooltip>
              }>
              <g
                className="milestone-icon"
                id="Milestone4"
                filter="url(#filter3_d_147_6)">
                <path
                  id="Vector_9"
                  d="M504.249 117.15C504.349 118.454 504.45 119.657 504.45 119.657C504.45 119.657 504.75 118.454 504.951 117.15H504.851C504.55 117.15 504.45 117.15 504.249 117.15Z"
                  fill="url(#paint6_linear_147_6)"
                />
                <g id="Group_3">
                  <path
                    id="Vector_10"
                    d="M485.908 101.812V123.065L504.249 133.692L522.59 123.065V101.812L504.249 91.2856L485.908 101.812ZM510.162 99.9073C510.463 100.81 510.262 101.511 510.864 102.213C511.465 102.915 512.167 102.614 513.069 103.216C514.271 103.917 515.173 105.421 515.173 106.825C515.173 106.825 515.173 106.925 515.173 107.025C515.073 108.529 513.971 109.231 513.971 109.932C513.971 110.033 513.971 110.133 514.071 110.233C514.372 110.835 515.073 110.634 516.075 111.436C516.877 112.038 517.679 113.241 517.679 114.343C517.679 114.644 517.679 114.845 517.579 115.045C517.078 116.248 515.574 116.649 515.274 116.649C513.369 117.151 512.768 115.947 510.964 116.348C509.36 116.649 509.36 117.652 507.857 117.752C506.955 117.852 506.554 117.451 505.853 117.251C505.452 119.156 505.051 121.161 505.051 121.361C505.051 121.762 505.552 127.476 505.552 127.476C503.347 127.877 502.946 127.476 502.946 127.476L503.447 119.557C503.447 119.557 503.347 118.554 503.147 117.451C502.545 117.752 501.944 118.253 500.641 118.354C499.138 118.554 496.833 118.253 496.031 116.95C495.83 116.649 495.83 116.449 495.83 116.148C495.83 115.847 495.931 115.647 495.931 115.246C495.931 114.945 495.931 114.644 495.73 114.243C495.029 112.539 493.525 112.739 492.824 111.135C492.824 111.135 492.623 110.534 492.623 109.832C492.623 109.431 492.623 108.93 492.824 108.529C493.225 107.627 493.726 107.526 495.029 106.323C495.129 106.223 496.231 105.12 496.933 104.118C498.236 102.113 497.534 101.11 498.436 99.5063C499.739 97.2005 503.347 96.0977 506.254 96.8997C506.755 97.3007 509.461 98.0025 510.162 99.9073Z"
                    fill="url(#paint7_linear_147_6)"
                  />
                </g>
              </g>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <p className="mb-0 fw-bold">{t("ProgressMilestone3H")}</p>
                </Tooltip>
              }>
              <g
                className="milestone-icon"
                id="Milestone3"
                filter="url(#filter4_d_147_6)">
                <path
                  id="Vector_11"
                  d="M350.249 117.15C350.349 118.454 350.45 119.657 350.45 119.657C350.45 119.657 350.75 118.454 350.951 117.15H350.851C350.55 117.15 350.45 117.15 350.249 117.15Z"
                  fill="url(#paint8_linear_147_6)"
                />
                <g id="Group_4">
                  <path
                    id="Vector_12"
                    d="M331.908 101.812V123.065L350.249 133.692L368.59 123.065V101.812L350.249 91.2856L331.908 101.812ZM356.162 99.9073C356.463 100.81 356.262 101.511 356.864 102.213C357.465 102.915 358.167 102.614 359.069 103.216C360.271 103.917 361.173 105.421 361.173 106.825C361.173 106.825 361.173 106.925 361.173 107.025C361.073 108.529 359.971 109.231 359.971 109.932C359.971 110.033 359.971 110.133 360.071 110.233C360.372 110.835 361.073 110.634 362.075 111.436C362.877 112.038 363.679 113.241 363.679 114.343C363.679 114.644 363.679 114.845 363.579 115.045C363.078 116.248 361.574 116.649 361.274 116.649C359.369 117.151 358.768 115.947 356.964 116.348C355.36 116.649 355.36 117.652 353.857 117.752C352.955 117.852 352.554 117.451 351.853 117.251C351.452 119.156 351.051 121.161 351.051 121.361C351.051 121.762 351.552 127.476 351.552 127.476C349.347 127.877 348.946 127.476 348.946 127.476L349.447 119.557C349.447 119.557 349.347 118.554 349.147 117.451C348.545 117.752 347.944 118.253 346.641 118.354C345.138 118.554 342.833 118.253 342.031 116.95C341.83 116.649 341.83 116.449 341.83 116.148C341.83 115.847 341.931 115.647 341.931 115.246C341.931 114.945 341.931 114.644 341.73 114.243C341.029 112.539 339.525 112.739 338.824 111.135C338.824 111.135 338.623 110.534 338.623 109.832C338.623 109.431 338.623 108.93 338.824 108.529C339.225 107.627 339.726 107.526 341.029 106.323C341.129 106.223 342.231 105.12 342.933 104.118C344.236 102.113 343.534 101.11 344.436 99.5063C345.739 97.2005 349.347 96.0977 352.254 96.8997C352.755 97.3007 355.461 98.0025 356.162 99.9073Z"
                    fill="url(#paint9_linear_147_6)"
                  />
                </g>
              </g>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <p className="mb-0 fw-bold">{t("ProgressMilestone2H")}</p>
                </Tooltip>
              }>
              <g
                className="milestone-icon"
                id="Milestone2"
                filter="url(#filter5_d_147_6)">
                <path
                  id="Vector_13"
                  d="M196.249 117.15C196.35 118.454 196.45 119.657 196.45 119.657C196.45 119.657 196.75 118.454 196.951 117.15H196.851C196.55 117.15 196.45 117.15 196.249 117.15Z"
                  fill="url(#paint10_linear_147_6)"
                />
                <g id="Group_5">
                  <path
                    id="Vector_14"
                    d="M177.909 101.812V123.065L196.249 133.692L214.59 123.065V101.812L196.249 91.2856L177.909 101.812ZM202.162 99.9073C202.463 100.81 202.263 101.511 202.864 102.213C203.465 102.915 204.167 102.614 205.069 103.216C206.271 103.917 207.173 105.421 207.173 106.825C207.173 106.825 207.173 106.925 207.173 107.025C207.073 108.529 205.971 109.231 205.971 109.932C205.971 110.033 205.971 110.133 206.071 110.233C206.372 110.835 207.073 110.634 208.075 111.436C208.877 112.038 209.679 113.241 209.679 114.343C209.679 114.644 209.679 114.845 209.579 115.045C209.078 116.248 207.574 116.649 207.274 116.649C205.369 117.151 204.768 115.947 202.964 116.348C201.361 116.649 201.361 117.652 199.857 117.752C198.955 117.852 198.554 117.451 197.853 117.251C197.452 119.156 197.051 121.161 197.051 121.361C197.051 121.762 197.552 127.476 197.552 127.476C195.347 127.877 194.946 127.476 194.946 127.476L195.447 119.557C195.447 119.557 195.347 118.554 195.147 117.451C194.545 117.752 193.944 118.253 192.641 118.354C191.138 118.554 188.833 118.253 188.031 116.95C187.831 116.649 187.831 116.449 187.831 116.148C187.831 115.847 187.931 115.647 187.931 115.246C187.931 114.945 187.931 114.644 187.73 114.243C187.029 112.539 185.525 112.739 184.824 111.135C184.824 111.135 184.623 110.534 184.623 109.832C184.623 109.431 184.623 108.93 184.824 108.529C185.225 107.627 185.726 107.526 187.029 106.323C187.129 106.223 188.231 105.12 188.933 104.118C190.236 102.113 189.534 101.11 190.436 99.5063C191.739 97.2005 195.347 96.0977 198.254 96.8997C198.755 97.3007 201.461 98.0025 202.162 99.9073Z"
                    fill="url(#paint11_linear_147_6)"
                  />
                </g>
              </g>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <p className="mb-0 fw-bold">{t("ProgressMilestone1H")}</p>
                </Tooltip>
              }>
              <g
                className="milestone-icon"
                id="Milestone1"
                filter="url(#filter6_d_147_6)">
                <path
                  id="Vector_15"
                  d="M42.2493 117.15C42.3495 118.454 42.4498 119.657 42.4498 119.657C42.4498 119.657 42.7504 118.454 42.9509 117.15H42.8506C42.55 117.15 42.4498 117.15 42.2493 117.15Z"
                  fill="url(#paint12_linear_147_6)"
                />
                <g id="Group_6">
                  <path
                    id="Vector_16"
                    d="M23.9085 101.812V123.065L42.2492 133.692L60.5899 123.065V101.812L42.2492 91.2856L23.9085 101.812ZM48.1623 99.9073C48.463 100.81 48.2625 101.511 48.8639 102.213C49.4652 102.915 50.1668 102.614 51.0688 103.216C52.2714 103.917 53.1734 105.421 53.1734 106.825C53.1734 106.825 53.1734 106.925 53.1734 107.025C53.0732 108.529 51.9708 109.231 51.9708 109.932C51.9708 110.033 51.9708 110.133 52.071 110.233C52.3717 110.835 53.0732 110.634 54.0754 111.436C54.8772 112.038 55.679 113.241 55.679 114.343C55.679 114.644 55.679 114.845 55.5788 115.045C55.0777 116.248 53.5743 116.649 53.2737 116.649C51.3694 117.151 50.7681 115.947 48.9641 116.348C47.3605 116.649 47.3605 117.652 45.8572 117.752C44.9552 117.852 44.5543 117.451 43.8527 117.251C43.4519 119.156 43.051 121.161 43.051 121.361C43.051 121.762 43.5521 127.476 43.5521 127.476C41.3472 127.877 40.9463 127.476 40.9463 127.476L41.4474 119.557C41.4474 119.557 41.3472 118.554 41.1467 117.451C40.5454 117.752 39.9441 118.253 38.6412 118.354C37.1378 118.554 34.8327 118.253 34.031 116.95C33.8305 116.649 33.8305 116.449 33.8305 116.148C33.8305 115.847 33.9307 115.647 33.9307 115.246C33.9307 114.945 33.9307 114.644 33.7303 114.243C33.0287 112.539 31.5254 112.739 30.8238 111.135C30.8238 111.135 30.6234 110.534 30.6234 109.832C30.6234 109.431 30.6234 108.93 30.8238 108.529C31.2247 107.627 31.7258 107.526 33.0287 106.323C33.1289 106.223 34.2314 105.12 34.933 104.118C36.2358 102.113 35.5343 101.11 36.4363 99.5063C37.7392 97.2005 41.3472 96.0977 44.2536 96.8997C44.7548 97.3007 47.4608 98.0025 48.1623 99.9073Z"
                    fill="url(#paint13_linear_147_6)"
                  />
                </g>
              </g>
            </OverlayTrigger>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_147_6"
            x="0.465462"
            y="0.929688"
            width="853.23"
            height="94.0527"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_147_6"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_147_6"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_147_6"
            x="0.465462"
            y="0.929688"
            width={progressWidth}
            height="94.0527"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_147_6"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_147_6"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_147_6"
            x="619.908"
            y="71.2856"
            width="76.6814"
            height="82.4062"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_147_6"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_147_6"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_147_6"
            x="465.908"
            y="71.2856"
            width="76.6814"
            height="82.4062"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_147_6"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_147_6"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_d_147_6"
            x="311.908"
            y="71.2856"
            width="76.6814"
            height="82.4062"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_147_6"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_147_6"
              result="shape"
            />
          </filter>
          <filter
            id="filter5_d_147_6"
            x="157.909"
            y="71.2856"
            width="76.6814"
            height="82.4062"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_147_6"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_147_6"
              result="shape"
            />
          </filter>
          <filter
            id="filter6_d_147_6"
            x="3.90849"
            y="71.2856"
            width="76.6814"
            height="82.4062"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_147_6"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_147_6"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_147_6"
            x1="824.777"
            y1="116.131"
            x2="823.317"
            y2="116.79"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_147_6"
            x1="808.184"
            y1="101.018"
            x2="801.907"
            y2="106.891"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_147_6"
            x1="823.171"
            y1="101.005"
            x2="817.059"
            y2="106.62"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_147_6"
            x1="838.895"
            y1="72.636"
            x2="807.79"
            y2="116.039"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_147_6"
            x1="659.108"
            y1="116.065"
            x2="657.652"
            y2="116.723"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_147_6"
            x1="684.805"
            y1="72.9232"
            x2="653.704"
            y2="116.326"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_147_6"
            x1="505.108"
            y1="116.065"
            x2="503.652"
            y2="116.723"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_147_6"
            x1="530.805"
            y1="72.9232"
            x2="499.704"
            y2="116.326"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_147_6"
            x1="351.108"
            y1="116.065"
            x2="349.652"
            y2="116.723"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_147_6"
            x1="376.805"
            y1="72.9232"
            x2="345.704"
            y2="116.326"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_147_6"
            x1="197.108"
            y1="116.065"
            x2="195.652"
            y2="116.723"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_147_6"
            x1="222.805"
            y1="72.9232"
            x2="191.704"
            y2="116.326"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_147_6"
            x1="43.108"
            y1="116.065"
            x2="41.6517"
            y2="116.723"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_147_6"
            x1="68.8051"
            y1="72.9232"
            x2="37.704"
            y2="116.326"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDE085" />
            <stop offset="1" stopColor="#DE9800" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default ProgressSVG;
