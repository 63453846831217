import { Suspense, lazy } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useSelector, useDispatch } from "react-redux";
import { fadeInOut } from "../utils/animations";
import {
  setIsMilestone,
  setIsUserOptin,
  setIsCountdown,
  setModalOpenWelcome,
  setModalOpenSuccess,
  setModalOpenMilestone,
  setModalOpenOptin,
  setModalOpenComplete,
  treeNameReducer,
  setNumPlantedToday,
  setPlantedTrees,
} from "../store/gameSlice";

import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Countdown from "react-countdown";
import useSound from "use-sound";
import scrollIntoView from "scroll-into-view-if-needed";

import Forest from "./Forest";
import Trees from "./Trees";
import ModalWelcome from "../components/Modals/ModalWelcome";
//import ModalSuccess from "../components/Modals/ModalSuccess";
import ModalMilestone from "../components/Modals/ModalMilestone";
//import ModalOptin from "../components/Modals/ModalOptin";
import ModalComplete from "../components/Modals/ModalComplete";
import Progress from "./Progress";

// Audio
import soundBG from "../assets/sounds/bg-sound.mp3";
import soundMilestone from "../assets/sounds/milestone_cilj.mp3";

// Media
import { ReactComponent as BG } from "../assets/images/BG.svg";
import iconSoundYes from "../assets/images/icon-sound-yes.svg";
import iconSoundNo from "../assets/images/icon-sound-no.svg";
import TreeJavor from "../assets/images/tree_javor.svg";
import TreeCesnja from "../assets/images/tree_cesnja.svg";
import TreeLipa from "../assets/images/tree_lipa.svg";
import TreeJablana from "../assets/images/tree_jablana.svg";
import TreeKostanj from "../assets/images/tree_kostanj.svg";
import TooltipTreeJavor from "../assets/images/tooltip-javor.png";
import TooltipTreeCesnja from "../assets/images/tooltip-cesnja.png";
import TooltipTreeLipa from "../assets/images/tooltip-lipa.png";
import TooltipTreeJablana from "../assets/images/tooltip-jablana.png";
import TooltipTreeKostanj from "../assets/images/tooltip-kostanj.png";

const ModalSuccess = lazy(() => import("../components/Modals/ModalSuccess"));
const ModalOptin = lazy(() => import("../components/Modals/ModalOptin"));

function Game() {
  const [isLoading, setIsLoading] = useState(false);
  const [disableModalMilestone, setDisableModalMilestone] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const treeName = useSelector((state) => state.game.treeName);
  const refDrag = useRef(null);
  const refWrapperDroppable = useRef(null);

  const [progressAnimate, setProgressAnimate] = useState();

  const isMilestone = useSelector((state) => state.game.milestone);
  const isUserOptin = useSelector((state) => state.game.useroptin);
  const isCountdown = useSelector((state) => state.game.countdown);
  const modalWelcome = useSelector((state) => state.game.showModalWelcome);
  const modalSuccess = useSelector((state) => state.game.showModalSuccess);
  const modalMilestone = useSelector((state) => state.game.showModalMilestone);
  const modalOptin = useSelector((state) => state.game.showModalOptin);
  const modalComplete = useSelector((state) => state.game.showModalComplete);
  const plantedTrees = useSelector((state) => state.game.showPlantedTrees);
  const totalCount = useSelector((state) => state.game.numPlantedTress);
  const numPlantedToday = useSelector((state) => state.game.numPlantedToday);

  const currentLanguage = i18n.language;

  const [sound, setSound] = useState(false);
  const [play, { stop }] = useSound(soundBG, { volume: 0.6 });
  const [playMilestone] = useSound(soundMilestone, { volume: 0.8 });

  const toggleSound = () => {
    setSound(!sound);
  };

  // TEMP
  const showModal = () => {
    dispatch(setModalOpenOptin(true));
    document.body.classList.add("modal-open");
  };

  const showModalWelcome = () => {
    dispatch(setModalOpenWelcome(true));
    document.body.classList.add("modal-open");
  };

  const showModalMilestone = () => {
    dispatch(setModalOpenMilestone(true));
    document.body.classList.add("modal-open");
  };

  const showModalComplete = () => {
    dispatch(setModalOpenComplete(true));
    document.body.classList.add("modal-open");
  };

  const clearLocalStorage = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  const closeModalWelcome = () => {
    dispatch(setModalOpenWelcome(false));
    document.body.classList.remove("modal-open");
    localStorage.setItem("closed", "true");
    setProgressAnimate(true);
    toggleSound();
    play();

    if (!isUserOptin && localStorage.getItem("name") == null) {
      showModal();
    }
  };

  const closeModalSuccess = () => {
    dispatch(setModalOpenSuccess(false));
    //dispatch(treeNameReducer(""));
    document.body.classList.remove("modal-open");

    // Show unfolded tree (first that was not unfolded yet)
    let newTree = document.querySelector("#trees > g:not(.unfold)");
    if (newTree !== null) {
      newTree.style.transitionDelay = "0s";
      newTree.classList.add("unfold");

      scrollIntoView(newTree, {
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
    }
    console.log("newTree", newTree);
    if (isMilestone && disableModalMilestone === false) {
      dispatch(setModalOpenMilestone(true));
      document.body.classList.add("modal-open");
      playMilestone();
    }
    setTimeout(() => {
      if (JSON.parse(localStorage.getItem("token"))) {
        axios
          .get(
            "https://api.escapebox.si/quizbox/plant-tree/ue7ad-idu6s5a-medexv2",
            {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("token")),
              },
            }
          )
          .then((res) => {
            if (
              numPlantedToday > 4 &&
              !isUserOptin &&
              localStorage.getItem("name") == null
            ) {
              dispatch(setModalOpenOptin(true));
              document.body.classList.add("modal-open");
              //dispatch(setIsCountdown(true));
            }

            if (numPlantedToday > 4) {
              refDrag.current.classList.add("inactive");
              dispatch(setIsCountdown(true));
            }
          })
          .catch(function (error) {
            if (error.response) {
              //setValidated(false);
              //setErrorMessage(error.response.data.error_desc);
              console.log(error.response.data);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }

            //setLoading(false);
          });
      }
    }, 1500);
  };

  const closeModalMilestone = () => {
    /*     if (!isUserOptin) {
      dispatch(setModalOpenOptin(true));
      document.body.classList.add("modal-open");
    }
 */
    setDisableModalMilestone(true);
    dispatch(setModalOpenMilestone(false));
    document.body.classList.remove("modal-open");
  };

  const closeModalOptin = () => {
    dispatch(setModalOpenOptin(false));

    //dispatch(setModalOpenComplete(true));

    document.body.classList.remove("modal-open");
  };

  const closeModalComplete = () => {
    dispatch(setModalOpenComplete(false));
    document.body.classList.remove("modal-open");
  };

  const Completionist = () => <h2 className="display-2 mb-0">UP</h2>;

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state

      dispatch(setIsCountdown(false));

      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <h2 className="display-2 mb-0">
          {hours}
          <span>h</span> {minutes}
          <span>m</span> {seconds}
          <span>s</span>
        </h2>
      );
    }
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() < today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  /*     useEffect(() => {
    // Show success modal when drop successful
    if (treeName != "") {
      window.scrollTo({ top: 0 });

      dispatch(setModalOpenSuccess(true));
      document.body.classList.add("modal-open");
    }
  }, [treeName]); */

  useEffect(() => {
    setIsLoading(true);
    refWrapperDroppable.current.scrollTo(600, 0);
    if (localStorage.getItem("token") === null) {
      dispatch(setModalOpenWelcome(true));
      document.body.classList.add("modal-open");
    } else {
      setTimeout(() => {
        setProgressAnimate(true);
      }, 500);
    }

    if (localStorage.getItem("name")) {
      dispatch(setIsUserOptin(true));
    }

    //isToday();

    // don't show countdown
    /* if (
      isToday(new Date(localStorage.getItem("time"))) ||
      localStorage.getItem("time") === null
    ) {
      localStorage.setItem("countdown", "false");
    } */

    if (JSON.parse(localStorage.getItem("token"))) {
      axios
        .get(
          "https://api.escapebox.si/quizbox/plant-tree/ue7ad-idu6s5a-medexv2",
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          if (numPlantedToday > 4 && res.data.plantedToday === true) {
            refDrag.current.classList.add("inactive");
            console.log("SETCOUNTDOWN");
            dispatch(setIsCountdown(true));
          } else {
            refDrag.current.classList.remove("inactive");
            dispatch(setIsCountdown(false));
            dispatch(setNumPlantedToday(0));
          }

          console.log(res.data);

          dispatch(setPlantedTrees(plantedTrees));

          let listTrees = document.querySelectorAll("#trees > g");

          /*     if (!modalWelcome || !modalOptin) { */
          console.log("plantedTrees", res.data.planted_trees);
          Array.from(listTrees)
            .slice(0, res.data.planted_trees)
            .map((elem) => {
              elem.classList.add("unfold");
            });

          setIsLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            //setValidated(false);
            //setErrorMessage(error.response.data.error_desc);
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }

          //setLoading(false);
        });
    }
  }, []);

  /*   useEffect(() => {
    if (plantedTrees !== "" && isUserOptin) {
      let listTrees = document.querySelectorAll("#trees > g");

      console.log("plantedTrees", plantedTrees);
      Array.from(listTrees)
        .slice(0, plantedTrees)
        .map((elem) => {
          elem.classList.add("unfold");
        });
    }
  }, [isUserOptin, plantedTrees]); */

  /* 
  useEffect(() => {
    // Show unfolded trees after welcome modal close
    let listTrees = document.querySelectorAll("#trees > g");
    alert("YO");
    if (!modalWelcome || !modalOptin) {
      console.log(plantedTrees);
      Array.from(listTrees)
        .slice(0, plantedTrees)
        .map((elem) => {
          elem.classList.add("unfold");
        });
    }
  }, []); */

  useEffect(() => {
    if (sound) {
      play();
    } else {
      stop();
    }
  }, [sound]);

  return (
    <>
      <motion.div
        className="game-wrapper"
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit">
        {/* Forest */}
        <div ref={refWrapperDroppable} className="wrapper-droppable noselect">
          <div className="scrollable">
            <Forest refDrag={refDrag} targetKey="tree" imageForest={<BG />} />
          </div>
        </div>

        {/* Stuff on the forrest */}
        <div className="wrapper-inside">
          <div className="upper text-center">
            <div className="progress-total mt-2 mt-sm-0">
              <Progress animate={progressAnimate} />
            </div>

            <h1 className="display-5 mt-3 px-3 px-sm-0">
              {t("HomeInstructionMsg")}
            </h1>
          </div>

          {isCountdown && (
            <motion.div
              className="middle"
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 1, duration: 1, ease: "easeInOut" }}>
              <div className="countdown rounded text-center px-5 py-4">
                <p className="mb-1 text-white">Naslednjega lahko posadiš čez</p>
                <Countdown
                  date={new Date().setHours(24, 0, 0, 0)}
                  renderer={renderer}
                />
              </div>
            </motion.div>
          )}

          <div className="bottom">
            <div className="draggable noselect" ref={refDrag}>
              <Trees
                ident="javor"
                label={t("Tree1")}
                targetKey="tree"
                imageTree={TreeJavor}
                tooltipImage={TooltipTreeJavor}
                tooltipText={t("Tree1Desc")}
              />
              <Trees
                ident="cesnja"
                label={t("Tree2")}
                targetKey="tree"
                imageTree={TreeCesnja}
                tooltipImage={TooltipTreeCesnja}
                tooltipText={t("Tree2Desc")}
              />
              <Trees
                ident="lipa"
                label={t("Tree3")}
                targetKey="tree"
                imageTree={TreeLipa}
                tooltipImage={TooltipTreeLipa}
                tooltipText={t("Tree3Desc")}
              />
              <Trees
                ident="jablana"
                label={t("Tree4")}
                targetKey="tree"
                imageTree={TreeJablana}
                tooltipImage={TooltipTreeJablana}
                tooltipText={t("Tree4Desc")}
              />
              <Trees
                ident="kostanj"
                label={t("Tree5")}
                targetKey="tree"
                imageTree={TreeKostanj}
                tooltipImage={TooltipTreeKostanj}
                tooltipText={t("Tree5Desc")}
              />
            </div>
          </div>
        </div>

        <ModalContainer>
          {modalWelcome && (
            <ModalWelcome
              modalOpen={modalWelcome}
              handleClose={closeModalWelcome}
            />
          )}

          {modalSuccess && (
            <Suspense fallback={<p>LOADING</p>}>
              <ModalSuccess
                modalOpen={modalSuccess}
                handleClose={closeModalSuccess}
              />
            </Suspense>
          )}

          {modalMilestone && disableModalMilestone === false && (
            <ModalMilestone
              modalOpen={modalMilestone}
              handleClose={closeModalMilestone}
            />
          )}

          {modalOptin && (
            <Suspense fallback={<p>LOADING</p>}>
              <ModalOptin
                refDrag={refDrag}
                modalOpen={modalOptin}
                handleClose={closeModalOptin}
              />
            </Suspense>
          )}

          {modalComplete && (
            <ModalComplete
              modalOpen={modalComplete}
              handleClose={closeModalComplete}
            />
          )}
        </ModalContainer>

        <img
          className="icon-sound"
          onClick={toggleSound}
          src={process.env.PUBLIC_URL + (sound ? iconSoundYes : iconSoundNo)}
        />
        <div className="temp-menu position-absolute d-none">
          <span className="small" onClick={clearLocalStorage}>
            Clear
          </span>

          <span className="small" onClick={showModal}>
            Optin
          </span>
          <span className="small" onClick={showModalWelcome}>
            Welcome
          </span>
          <span className="small" onClick={showModalMilestone}>
            Milestone
          </span>
          <span className="small" onClick={showModalComplete}>
            Complete
          </span>
        </div>

        <CookieConsent
          location="bottom"
          buttonText={t("OK")}
          buttonClasses="btn btn-consent btn-primary text-uppercase text-secondary"
          contentClasses="mb-3"
          cookieName="MedexCookieConsent"
          disableStyles="true"
          expires={150}>
          {t("CookiesP1")}
          <br />
          <Link to={`${t("CookiesLink")}?lang=${currentLanguage}`}>
            {t("CookiesP2")}
          </Link>
        </CookieConsent>
      </motion.div>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="wrapper-outside row row-cols-2 row-cols-md-4 g-3 g-md-3">
        <div className="col">
          <button
            onClick={() =>
              navigate("/osupljiva-dejstva?lang=" + currentLanguage)
            }
            className="btn btn-green text-white fw-bold px-4 py-1">
            {t("PageOsupljivaTitle")}
          </button>
        </div>
        <div className="col">
          <button
            onClick={() => navigate("/pomagaj-cebelam?lang=" + currentLanguage)}
            className="btn btn-green text-white fw-bold px-4 py-1">
            {t("PagePomagajTitle")}
          </button>
        </div>
        <div className="col">
          <button
            onClick={() => navigate("/cebelji-izdelki?lang=" + currentLanguage)}
            className="btn btn-green text-white fw-bold px-4 py-1">
            {t("PageIzdelkiTitle")}
          </button>
        </div>
        <div className="col">
          <button
            onClick={() => navigate("/o-medexu?lang=" + currentLanguage)}
            className="btn btn-green text-white fw-bold px-4 py-1">
            {t("PageOMedexuTitle")}
          </button>
        </div>
      </motion.div>
    </>
  );
}

const ModalContainer = ({ children }) => (
  <AnimatePresence initial={true} exitBeforeEnter={true}>
    {children}
  </AnimatePresence>
);

export default Game;
