import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import { dropIn } from "../../utils/animations";
import Backdrop from "./Backdrop";
import useSound from "use-sound";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import IconClose from "../../assets/images/icon-close-white.svg";
import JablanaSVG from "../SVG/JablanaSVG";
import ShareFb from "../../assets/images/share-fb-white.svg";
import ShareLn from "../../assets/images/share-ln-white.svg";
import ShareTw from "../../assets/images/share-twitter-white.svg";
import IconMilestone from "../../assets/images/icon-milestone-complete.svg";
import soundMilestone from "../../assets/sounds/milestone_cilj.mp3";

const ModalComplete = ({ handleClose, content, type }) => {
  const { t, i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  const currentLanguage = i18n.language;

  const container = {
    hidden: { opacity: 0 },

    show: {
      opacity: 1,

      transition: {
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,

      transition: {
        duration: 1,
      },
    },
  };

  const buttons = {
    hidden: { opacity: 0, y: "30vh" },
    show: {
      opacity: 1,
      y: "0",
      transition: {
        duration: 1,
        type: "spring",
        damping: 20,
      },
    },
  };

  const [play] = useSound(soundMilestone, { volume: 0.3 });

  useEffect(() => {
    play();
  }, []);

  return (
    <Backdrop onClick={handleClose} theme="complete">
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal-framer rounded pt-3 pt-sm-0"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit">
        <img className="icon-close" src={IconClose} onClick={handleClose} />

        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="modal-text col-11 col-sm-8 mx-auto pb-5 pt-1 text-center">
          <motion.div
            initial={{ opacity: 0, scale: 2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, ease: "easeInOut" }}>
            <img className="icon-milestone" src={IconMilestone} />
            <h1 className="display-5 text-primary text-white text-center">
              {t("ModalCompleteSuccessMsg")}
            </h1>
          </motion.div>

          <motion.div
            variants={item}
            className="row g-2 g-sm-3 milestone-numbers my-3 my-sm-3">
            <div className="col-6">
              <div className="bg-white opacity-90 rounded py-4">
                <p className="lead fw-bold mb-0 text-green-alt">
                  {t("ModalCompletePlanted")}
                </p>
                <h1 className="text-green display-2 mb-0 lh-0">18.000</h1>
                <h2 className="display-7 lh-1 text-green-alt">{t("dreves")}</h2>
              </div>
            </div>

            <div className="col-6">
              <div className="bg-white opacity-90 rounded py-4">
                <p className="lead fw-bold mb-0 text-green-alt">
                  {t("Kar je izravnalo")}
                </p>
                <h1 className="text-green display-2 mb-0 lh-0">3.600 ton</h1>
                <h2 className="display-7 lh-1 text-green-alt">{t("CO₂")}</h2>
              </div>
            </div>
            <div className="col-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("ModalCompleteXtra"),
                }}></div>
            </div>
          </motion.div>

          <motion.div
            variants={buttons}
            className="row g-3 d-flex justify-content-center">
            <div className="col-12">
              <p className="lead fw-bold">{t("ShareTitle")}</p>
              <FacebookShareButton
                url={`http://help-a-bee.com/?lang=${currentLanguage}`}>
                <img className="mx-2" src={ShareFb} />
              </FacebookShareButton>
              <TwitterShareButton
                url={`http://help-a-bee.com/?lang=${currentLanguage}`}>
                <img className="mx-2" src={ShareTw} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={`http://help-a-bee.com/?lang=${currentLanguage}`}>
                <img className="mx-2" src={ShareLn} />
              </LinkedinShareButton>

              <p className="mt-4">{t("ShareDesc")}</p>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </Backdrop>
  );
};

export default ModalComplete;
