import gsap from "gsap";

export var treeAnimationJablana = function () {
  let idSVG = "#drevo-jablana" + " ";

  gsap.set(idSVG + "#cvetki > *", {
    transformOrigin: "50% 50%",
    scale: 0,
    rotate: -180,
  });
  gsap.set(idSVG + "#cvetki", { opacity: 1 });

  let javorAnim = gsap.timeline({
    defaults: { ease: "sine.out" },
    repeat: 0,
    delay: 0.5,
  });
  javorAnim.to(
    idSVG + "#cvetki > *",
    0.75,
    { scale: 1, rotate: 0, stagger: 0.06 },
    0
  );

  gsap.set(idSVG + "#wings > *:nth-child(1)", {
    rotate: -60,
    svgOrigin: "116.3px 123.4px",
  });
  gsap.set(idSVG + "#wings > *:nth-child(2)", {
    rotate: 60,
    svgOrigin: "106px 123.4px",
  });
  gsap.set(idSVG + "#bee", { svgOrigin: "111.2px 118.2px" });

  gsap.to(idSVG + "#wings > *", 0.05, {
    rotate: 0,
    ease: "sine.inOut",
    repeat: -1,
    yoyo: true,
  });
  gsap.to(idSVG + "#bee", 0.75, { opacity: 1, delay: 3 });

  let beAnim = gsap.timeline({
    defaults: { ease: "power1.inOut" },
    repeat: -1,
    delay: 3.5,
    repeatDelay: 0.5,
  });
  beAnim
    .to(idSVG + "#bee", 1.6, { x: 328, y: 95 }, 0.5)
    .to(idSVG + "#bee", 0.9, { rotate: 60, repeat: 1, yoyo: true }, 0.5)
    .to(idSVG + "#bee", 1.6, { x: -80, y: 113 }, 3)
    .to(idSVG + "#bee", 0.9, { rotate: -90, repeat: 1, yoyo: true }, 3)
    .to(idSVG + "#bee", 1.6, { x: 124, y: 218 }, 6)
    .to(idSVG + "#bee", 0.9, { rotate: 90, repeat: 1, yoyo: true }, 6)
    .to(idSVG + "#bee", 1.6, { x: 0, y: 0 }, 9)
    .to(idSVG + "#bee", 0.9, { rotate: -30, repeat: 1, yoyo: true }, 9);
};

export var beeAnimation = function (id) {
  let idSVG = id + " ";
  let beAnim = gsap.timeline({
    defaults: { ease: "power1.inOut" },
    repeat: -1,
    delay: 3.5,
    repeatDelay: 0.5,
  });
  beAnim
    .to(idSVG + "#bee", 1.6, { x: 328, y: 95 }, 0.5)
    .to(idSVG + "#bee", 0.9, { rotate: 60, repeat: 1, yoyo: true }, 0.5)
    .to(idSVG + "#bee", 1.6, { x: -80, y: 113 }, 3)
    .to(idSVG + "#bee", 0.9, { rotate: -90, repeat: 1, yoyo: true }, 3)
    .to(idSVG + "#bee", 1.6, { x: 124, y: 218 }, 6)
    .to(idSVG + "#bee", 0.9, { rotate: 90, repeat: 1, yoyo: true }, 6)
    .to(idSVG + "#bee", 1.6, { x: 0, y: 0 }, 9)
    .to(idSVG + "#bee", 0.9, { rotate: -30, repeat: 1, yoyo: true }, 9);
};

export var treeAnimationCesnja = function () {
  let idSVG = "#drevo-cesnja" + " ";

  gsap.set(idSVG + "#cvetki > g", {
    transformOrigin: "50% 50%",
    scale: 0,
    rotate: -180,
  });
  gsap.set(idSVG + "#cvetki", { opacity: 1 });

  let javorAnim = gsap.timeline({
    defaults: { ease: "sine.out" },
    repeat: 0,
    delay: 0.5,
  });
  javorAnim.to(
    idSVG + "#cvetki > g",
    0.75,
    { scale: 1, rotate: 0, stagger: 0.1 },
    0
  );

  gsap.set(idSVG + "#wings > *:nth-child(1)", {
    rotate: -60,
    svgOrigin: "185.6px 126px",
  });
  gsap.set(idSVG + "#wings > *:nth-child(2)", {
    rotate: 60,
    svgOrigin: "175.3px 126px",
  });
  gsap.set(idSVG + "#bee", { svgOrigin: "180.5px 120.8px" });

  gsap.to(idSVG + "#wings > *", 0.05, {
    rotate: 0,
    ease: "sine.inOut",
    repeat: -1,
    yoyo: true,
  });
  gsap.to(idSVG + "#bee", 0.75, { opacity: 1, delay: 3 });

  let beAnim = gsap.timeline({
    defaults: { ease: "power1.inOut" },
    repeat: -1,
    delay: 3.5,
    repeatDelay: 0.5,
  });
  beAnim
    .to(idSVG + "#bee", 1.6, { x: 223, y: 104 }, 0.5)
    .to(idSVG + "#bee", 0.9, { rotate: 60, repeat: 1, yoyo: true }, 0.5)
    .to(idSVG + "#bee", 1.6, { x: -132, y: 136 }, 3)
    .to(idSVG + "#bee", 0.9, { rotate: -90, repeat: 1, yoyo: true }, 3)
    .to(idSVG + "#bee", 1.6, { x: 251, y: 228 }, 6)
    .to(idSVG + "#bee", 0.9, { rotate: 90, repeat: 1, yoyo: true }, 6)
    .to(idSVG + "#bee", 1.6, { x: 0, y: 0 }, 9)
    .to(idSVG + "#bee", 0.9, { rotate: -30, repeat: 1, yoyo: true }, 9);
};

export var treeAnimationJavor = function () {
  let idSVG = "#drevo-javor" + " ";

  gsap.set(idSVG + "#cvetki > g:nth-child(2n) > g", {
    transformOrigin: "50% 50%",
    scale: 0,
    rotate: -180,
  });
  gsap.set(idSVG + "#cvetki > g:nth-child(2n-1) > g", {
    transformOrigin: "50% 50%",
    scale: 0,
    rotate: 180,
  });
  gsap.set(idSVG + "#cvetki", { opacity: 1 });

  let javorAnim = gsap.timeline({
    defaults: { ease: "sine.out" },
    repeat: 0,
    delay: 0.5,
  });
  javorAnim
    .to(
      idSVG + "#cvetki > g:nth-child(1) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.1 },
      0
    )
    .to(
      idSVG + "#cvetki > g:nth-child(2) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.1 },
      0.25
    )
    .to(
      idSVG + "#cvetki > g:nth-child(3) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.1 },
      0.5
    )
    .to(
      idSVG + "#cvetki > g:nth-child(4) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.1 },
      0.75
    )
    .to(
      idSVG + "#cvetki > g:nth-child(5) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.1 },
      1
    )
    .to(
      idSVG + "#cvetki > g:nth-child(6) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.1 },
      1.25
    )
    .to(
      idSVG + "#cvetki > g:nth-child(7) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.1 },
      1.5
    )
    .to(
      idSVG + "#cvetki > g:nth-child(8) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.1 },
      1.75
    );

  gsap.set(idSVG + "#wings > *:nth-child(1)", {
    rotate: -60,
    svgOrigin: "30.4px 147.8px",
  });
  gsap.set(idSVG + "#wings > *:nth-child(2)", {
    rotate: 60,
    svgOrigin: "20.1px 147.8px",
  });
  gsap.set(idSVG + "#bee", { svgOrigin: "25.3px 142.65px", x: 90, y: 9 });

  gsap.to(idSVG + "#wings > *", 0.05, {
    rotate: 0,
    ease: "sine.inOut",
    repeat: -1,
    yoyo: true,
  });

  gsap.to(idSVG + "#bee", 0.75, { opacity: 1, delay: 3 });

  let beAnim = gsap.timeline({
    defaults: { ease: "power1.inOut" },
    repeat: -1,
    delay: 3.5,
    repeatDelay: 0.5,
  });
  beAnim
    .to(idSVG + "#bee", 1.6, { x: 390, y: 103 }, 0.5)
    .to(idSVG + "#bee", 0.9, { rotate: 60, repeat: 1, yoyo: true }, 0.5)
    .to(idSVG + "#bee", 1.6, { x: 84, y: 227 }, 3)
    .to(idSVG + "#bee", 0.9, { rotate: -90, repeat: 1, yoyo: true }, 3)
    .to(idSVG + "#bee", 1.6, { x: 346, y: 366 }, 6)
    .to(idSVG + "#bee", 0.9, { rotate: 90, repeat: 1, yoyo: true }, 6)
    .to(idSVG + "#bee", 1.6, { x: 90, y: 9 }, 9)
    .to(idSVG + "#bee", 0.9, { rotate: -30, repeat: 1, yoyo: true }, 9);
};

export var treeAnimationKostanj = function () {
  let idSVG = "#drevo-kostanj" + " ";

  gsap.set(idSVG + "#cvetki > g:nth-child(1) > g", {
    transformOrigin: "50% 50%",
    scale: 0,
    rotate: -180,
  });
  gsap.set(idSVG + "#cvetki > g:nth-child(2) > g", {
    transformOrigin: "50% 0%",
    scale: 0,
    rotate: 20,
  });
  gsap.set(idSVG + "#cvetki", { opacity: 1 });

  let javorAnim = gsap.timeline({
    defaults: { ease: "sine.out" },
    repeat: 0,
    delay: 0.5,
  });
  javorAnim
    .to(
      idSVG + "#cvetki > g:nth-child(1) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.15 },
      0
    )
    .to(
      idSVG + "#cvetki > g:nth-child(2) > g",
      0.75,
      { scale: 1, rotate: 0, stagger: 0.15 },
      0.07
    );

  gsap.set(idSVG + "#wings > *:nth-child(1)", {
    rotate: -60,
    svgOrigin: "242px 176.4px",
  });
  gsap.set(idSVG + "#wings > *:nth-child(2)", {
    rotate: 60,
    svgOrigin: "231.7px 176.4px",
  });
  gsap.set(idSVG + "#bee", { svgOrigin: "237px 171.2px" });

  gsap.to(idSVG + "#wings > *", 0.05, {
    rotate: 0,
    ease: "sine.inOut",
    repeat: -1,
    yoyo: true,
  });
  gsap.to(idSVG + "#bee", 0.75, { opacity: 1, delay: 3 });

  let beAnim = gsap.timeline({
    defaults: { ease: "power1.inOut" },
    repeat: -1,
    delay: 3.5,
    repeatDelay: 0.5,
  });
  beAnim
    .to(idSVG + "#bee", 1.6, { x: 275, y: 110 }, 0.5)
    .to(idSVG + "#bee", 0.9, { rotate: 60, repeat: 1, yoyo: true }, 0.5)
    .to(idSVG + "#bee", 1.6, { x: -157, y: 128 }, 3)
    .to(idSVG + "#bee", 0.9, { rotate: -90, repeat: 1, yoyo: true }, 3)
    .to(idSVG + "#bee", 1.6, { x: 238, y: 275 }, 6)
    .to(idSVG + "#bee", 0.9, { rotate: 90, repeat: 1, yoyo: true }, 6)
    .to(idSVG + "#bee", 1.6, { x: 0, y: 0 }, 9)
    .to(idSVG + "#bee", 0.9, { rotate: -30, repeat: 1, yoyo: true }, 9);
};

export var treeAnimationLipa = function () {
  let idSVG = "#drevo-lipa" + " ";

  gsap.set(idSVG + "#cvetki > g", {
    transformOrigin: "50% 50%",
    scale: 0,
    rotate: -180,
  });
  gsap.set(idSVG + "#cvetki", { opacity: 1 });

  let javorAnim = gsap.timeline({
    defaults: { ease: "sine.out" },
    repeat: 0,
    delay: 0.5,
  });
  javorAnim.to(
    idSVG + "#cvetki > g",
    0.75,
    { scale: 1, rotate: 0, stagger: 0.08 },
    0
  );

  gsap.set(idSVG + "#wings > *:nth-child(1)", {
    rotate: -60,
    svgOrigin: "174.3px 111.2px",
  });
  gsap.set(idSVG + "#wings > *:nth-child(2)", {
    rotate: 60,
    svgOrigin: "164px 111.2px",
  });
  gsap.set(idSVG + "#bee", { svgOrigin: "169.2px 106px" });

  gsap.to(idSVG + "#wings > *", 0.05, {
    rotate: 0,
    ease: "sine.inOut",
    repeat: -1,
    yoyo: true,
  });
  gsap.to(idSVG + "#bee", 0.75, { opacity: 1, delay: 3 });

  let beAnim = gsap.timeline({
    defaults: { ease: "power1.inOut" },
    repeat: -1,
    delay: 3.5,
    repeatDelay: 0.5,
  });
  beAnim
    .to(idSVG + "#bee", 1.6, { x: 257, y: 187 }, 0.5)
    .to(idSVG + "#bee", 0.9, { rotate: 60, repeat: 1, yoyo: true }, 0.5)
    .to(idSVG + "#bee", 1.6, { x: -75, y: 285 }, 3)
    .to(idSVG + "#bee", 0.9, { rotate: -90, repeat: 1, yoyo: true }, 3)
    .to(idSVG + "#bee", 1.6, { x: 213, y: 277 }, 6)
    .to(idSVG + "#bee", 0.9, { rotate: 90, repeat: 1, yoyo: true }, 6)
    .to(idSVG + "#bee", 1.6, { x: 0, y: 0 }, 9)
    .to(idSVG + "#bee", 0.9, { rotate: -30, repeat: 1, yoyo: true }, 9);
};


export var bikeAnimation = function () {
  
  function animBee(clTran, clBee, idBeeF, idBeeB, dir, del, rot, tranY) {

    gsap.set(clTran, {rotate: rot, y: tranY});

    let dirS = dir ? 1 : -1;

    gsap.set(idBeeB, {opacity: dir ? 0 : 1});
    gsap.set(idBeeF, {opacity: dir ? 1 : 0});
    
    let beeAnim = gsap.timeline({defaults: { ease: 'power1.inOut' }, repeat: -1, delay: del, repeatDelay: 0});
      beeAnim
        .to(clBee, 2, {x: 600}, 0)
        .to(clBee, 1, {rotate: 60, scale: dirS * 0.2 + 1, y: dirS * 40, repeat: 1, yoyo: true}, 0)
        .set(idBeeB, {opacity: dir ? 1 : 0}, 2)
        .set(idBeeF, {opacity: dir ? 0 : 1}, 2)
        .to(clBee, 2, {x: 0}, 2)
        .to(clBee, 1, {rotate: -60, scale: -dirS * 0.2 + 1, y: -dirS * 40, repeat: 1, yoyo: true}, 2);
  }



  let idSVG = '#kolesar-anim' + ' ';


  gsap.set(idSVG + '.wing-right', {rotate: -60, svgOrigin: '174px 172.2px'});
  gsap.set(idSVG + '.wing-left', {rotate: 60, svgOrigin: '182.1px 172.2px'});
  gsap.set([idSVG + '.bee-1',
        idSVG + '.bee-2'], {svgOrigin: '178.1px 168.1px'});
  gsap.set([idSVG + '.bee1-tran',
        idSVG + '.bee2-tran'], {svgOrigin: '478.1px 168.1px'});

  gsap.to([idSVG + '.wing-right', idSVG + '.wing-left'], 0.05, {rotate: 0, ease: 'sine.inOut', repeat: -1, yoyo: true});


  animBee(idSVG + '.bee1-tran', idSVG + '.bee-1', idSVG + '#bee-1f', idSVG + '#bee-1b', true, 0, 0, 0);
  animBee(idSVG + '.bee2-tran', idSVG + '.bee-2', idSVG + '#bee-2f', idSVG + '#bee-2b', false, -0.9, 10, 0);
  animBee(idSVG + '.bee3-tran', idSVG + '.bee-3', idSVG + '#bee-3f', idSVG + '#bee-3b', true, -2.4, -10, 70);
  animBee(idSVG + '.bee4-tran', idSVG + '.bee-4', idSVG + '#bee-4f', idSVG + '#bee-4b', false, -3.1, 15, 0);
  animBee(idSVG + '.bee5-tran', idSVG + '.bee-5', idSVG + '#bee-5f', idSVG + '#bee-5b', true, -4.5, -20, 100);





}