import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import LogoEb from "../assets/images/logo-eb.svg";
import LogoTreeceletSl from "../assets/images/treecelet-sl.png";
import LogoTreeceletEn from "../assets/images/treecelet-en.png";
import ShareFb from "../assets/images/share-fb.svg";
import ShareIg from "../assets/images/share-ig.svg";
import ShareTw from "../assets/images/share-twitter.svg";
import ShareYt from "../assets/images/share-yt.svg";
import ShareLn from "../assets/images/share-ln.svg";

const Footer = ({ currentLanguage }) => {
  const { t } = useTranslation();

  return (
    <footer className="p-1 p-sm-1">
      <div className="d-sm-flex justify-content-between align-items-center">
        <div className="social-share">
          <a href="https://www.facebook.com/medex.si/" target="_blank">
            <img alt="Facebook" className="mx-1" src={ShareFb} />
          </a>
          <a href="https://www.instagram.com/medex.si/" target="_blank">
            <img alt="Instagram" className="mx-1" src={ShareIg} />
          </a>
          <a href="https://www.youtube.com/user/Medex1954" target="_blank">
            <img alt="Youtube" className="mx-1" src={ShareYt} />
          </a>
          <a
            href="https://www.linkedin.com/company/medex-slovenia/"
            target="_blank">
            <img alt="Twitter" className="mx-1" src={ShareLn} />
          </a>
        </div>

        <div className="links">
          <ul className="list-inline mb-0">
            <li className="list-inline-item mx-sm-3 mx-md-5">
              <Link to={`/piskotki?lang=${currentLanguage}`}>
                {t("Cookies")}
              </Link>
            </li>
            <li className="list-inline-item mx-sm-3 mx-md-5">
              <a href={t("Pravila_in_pogoji")} target="_blank">
                {t("FooterPravila")}
              </a>
            </li>
            <li className="list-inline-item mx-sm-3 mx-md-5">
              <a href={t("FooterMedexLink")} target="_blank">
                {t("FooterMedexTitle")}
              </a>
            </li>
          </ul>
        </div>

        <div className="escapebox text-secondary">
          <a href="https://treecelet.si/" rel="noreferrer" target="_blank">
            <img alt="" className="me-2" src={LogoTreeceletEn} />
          </a>
          <a href="https://escapebox.si" rel="noreferrer" target="_blank">
            <img alt="" src={LogoEb} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
