import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setModalOpenOptin,
  setModalOpenMilestone,
  setModalOpenWelcome,
  setModalOpenComplete,
} from "../store/gameSlice";
import { fadeInOut } from "../utils/animations";
import { AnimatePresence, motion } from "framer-motion";

import Logo from "../assets/images/logo-medex.svg";

const Header = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [searchParams, setSearchParams] = useSearchParams();
  const windowUrl = window.location.search;

  const userData = useSelector((state) => state.game.userData);
  const dispatch = useDispatch();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
    setSearchParams({ lang: e.target.value });
  }

  const showModal = () => {
    dispatch(setModalOpenOptin(true));
  };

  useEffect(() => {
    if (windowUrl === "?lang=en") {
      i18n.changeLanguage("en");
    } else if (windowUrl === "?lang=sl") {
      i18n.changeLanguage("sl");
    } else {
      setSearchParams({ lang: currentLanguage });
    }
  }, []);

  return (
    <>
      <motion.header
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="p-1 p-sm-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="language-picker">
            <button
              onClick={changeLanguage}
              value="sl"
              type="button"
              className={
                "btn btn-link link-secondary py-0 px-2 " +
                (currentLanguage === "sl" ? "active" : "")
              }>
              slo
            </button>
            |
            <button
              onClick={changeLanguage}
              value="en"
              type="button"
              className={
                "btn btn-link link-secondary py-0 px-2 " +
                (currentLanguage === "en" ? "active" : "")
              }>
              en
            </button>
          </div>

          <div className="logo">
            <Link to={"/?lang=" + currentLanguage}>
              <img src={Logo} />
            </Link>
          </div>

          {Object.keys(userData).length !== 0 ||
          localStorage.getItem("name") ? (
            <div className="user text-secondary">
              {userData.name || localStorage.getItem("name")}
            </div>
          ) : (
            <div className="user text-secondary">
              <a onClick={showModal}>{t("Login")}</a>
            </div>
          )}
        </div>
        {/* LANGUAGE PICKER */}

        {/* LOGO  */}

        {/* USER 
      if !localstorage && !logged_user link to optin modal
      if localstorage && logged_user display name */}
      </motion.header>
    </>
  );
};

export default Header;
