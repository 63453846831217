import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { fadeInOut } from "../../utils/animations";

import BG from "../../assets/images/BG-static.png";

import Icon from "../../assets/images/icon-cebelca.svg";

const OsupljivaDejstva = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="pages pb-5"
      style={{ backgroundImage: `url(${BG})` }}>
      <div className="container-xl mt-6">
        <div className="wrapper variant-two rounded">
          <h1 className="display-5 text-green mb-2 text-center">
            {t("PageOsupljivaTitle")}
          </h1>
          <h2 className="text-center">{t("PagesSubtitle")}</h2>

          <div className="entry entry__icons row g-md-5 mt-3 position-relative text-center text-md-start">
            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva1H")}
              </h3>
              <p>{t("PageOsupljiva1P")}</p>
            </div>
            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva2H")}
              </h3>
              <p>{t("PageOsupljiva2P")}</p>
            </div>
            <div className="col-md-4 ">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva3H")}
              </h3>
              <p>{t("PageOsupljiva3P")}</p>
            </div>

            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva4H")}
              </h3>
              <p>{t("PageOsupljiva4P")}</p>
            </div>

            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva5H")}
              </h3>
              <p>{t("PageOsupljiva5P")}</p>
            </div>

            <div className="col-md-4 ">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva6H")}
              </h3>
              <p>{t("PageOsupljiva6P")}</p>
            </div>

            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva7H")}
              </h3>
              <p>{t("PageOsupljiva7P")}</p>
            </div>

            <div className="col-md-4 ">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva8H")}
              </h3>
              <p>{t("PageOsupljiva8P")}</p>
            </div>

            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva9H")}
              </h3>
              <p>{t("PageOsupljiva9P")}</p>
            </div>

            <div className="col-md-4 ">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva10H")}
              </h3>
              <p>{t("PageOsupljiva10P")}</p>
            </div>

            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva11H")}
              </h3>
              <p>{t("PageOsupljiva11P")}</p>
            </div>

            <div className="col-md-4 ">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva12H")}
              </h3>
              <p>{t("PageOsupljiva12P")}</p>
            </div>

            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva13H")}
              </h3>
              <p>{t("PageOsupljiva13P")}</p>
            </div>

            <div className="col-md-4 ">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva14H")}
              </h3>
              <p>{t("PageOsupljiva14P")}</p>
            </div>

            <div className="col-md-4">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("PageOsupljiva15H")}
              </h3>
              <p>{t("PageOsupljiva15P")}</p>
            </div>

            <div className="col-md-12 d-none">
              <h3 className="green text-green d-flex align-items-center justify-content-center justify-content-md-start mb-0">
                {t("")}
              </h3>
              <p>{t("")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 text-center">
        <a
          className="btn btn-yellow text-uppercase text-white px-5 mt-3"
          href={"/?lang=" + currentLanguage}>
          {t("Back")}
        </a>
      </div>
    </motion.div>
  );
};

export default OsupljivaDejstva;
