import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { dropIn } from "../../utils/animations";
import Backdrop from "./Backdrop";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import IconClose from "../../assets/images/icon-close.svg";
import IconUsp01 from "../../assets/images/icon-usp01.svg";
import IconUsp02 from "../../assets/images/icon-usp02.svg";
import IconUsp03 from "../../assets/images/icon-usp03.svg";
import IconUsp04 from "../../assets/images/icon-usp04.svg";
import IconUsp05 from "../../assets/images/icon-usp05.svg";

const ModalWelcome = ({ handleClose, content, type }) => {
  const { t, i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  const container = {
    hidden: { opacity: 0 },

    show: {
      opacity: 1,

      transition: {
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,

      transition: {
        duration: 1,
      },
    },
  };

  const buttons = {
    hidden: { opacity: 0, y: "30vh" },
    show: {
      opacity: 1,
      y: "0",
      transition: {
        duration: 1,
        type: "spring",
        damping: 20,
      },
    },
  };

  return (
    <Backdrop /*  onClick={handleClose} */>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal-framer rounded pt-3 pt-sm-0"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit">
        <img className="icon-close" src={IconClose} onClick={handleClose} />
        <PerfectScrollbar>
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className="modal-text col-11 col-sm-10 mx-auto py-5 text-center">
            <motion.div variants={item}>
              <h1 className="display-4 text-primary text-center">
                {t("ModalWelcomeH")}
              </h1>
            </motion.div>
            <motion.div variants={item}>
              <p>{t("ModalWelcomeP1")}</p>
              <p>
                <Trans i18nKey="ModalWelcomeP2" />
              </p>
              <p className="fw-bold">{t("ModalWelcomeP3")}</p>
            </motion.div>
            <motion.div variants={item}>
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="usps row gx-2 gx-sm-5 mt-5 d-flex justify-content-center">
                <motion.div variants={item} className="col-6 col-sm-4">
                  <img className="mb-3 img-fluid" src={IconUsp01} />
                  <p>{t("ModalWelcomeIconP1")}</p>
                </motion.div>
                <motion.div variants={item} className="col-6 col-sm-4">
                  <img className="mb-3 img-fluid" src={IconUsp05} />
                  <p>{t("ModalWelcomeIconP4")}</p>
                </motion.div>
                <motion.div variants={item} className="col-6 col-sm-4">
                  <img className="mb-3 img-fluid" src={IconUsp02} />
                  <p>{t("ModalWelcomeIconP2")}</p>
                </motion.div>
              </motion.div>
            </motion.div>
            <motion.div variants={buttons}>
              <div className="row g-2 d-flex justify-content-center mt-4">
                <div className="col-6">
                  <button
                    className="btn btn-primary text-uppercase text-white w-100"
                    type="button"
                    onClick={handleClose}>
                    {t("ModalWelcomeButton")}
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </PerfectScrollbar>
      </motion.div>
    </Backdrop>
  );
};

export default ModalWelcome;
