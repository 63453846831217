import axios from "axios";
import { useTranslation } from "react-i18next";
import { animate } from "framer-motion";
import React, { useEffect, useState } from "react";
import { setNumPlantedTrees, setIsMilestone } from "../store/gameSlice";
import { useSelector, useDispatch } from "react-redux";
import ProgressSVG from "./SVG/ProgressSVG";

const Progress = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMilestone = useSelector((state) => state.game.milestone);

  const [count, setCount] = useState(0);
  const [reCount, setReCount] = useState(0);

  const totalCount = useSelector((state) => state.game.numPlantedTrees);
  // TEMP NUM
  //let totalCount = 6000;

  // Get num of planted trees
  useEffect(() => {
    axios
      .get(
        "https://api.escapebox.si/quizbox/plant-tree/ue7ad-idu6s5a-medexv2",
        null
      )
      .then((res) => {
        console.log(res.data);
        //setTotalCount(res.data.total_planted_trees)
        dispatch(setNumPlantedTrees(res.data.total_planted_trees));

        //dispatch(setNumPlantedTrees(totalCount));
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  function calculatePercent(totalCount) {
    let percent = (totalCount * 840) / 18000;
    return percent;
  }

  function showNum() {
    //let numContainer = document.querySelector("#textNum");
    //numContainer.classList.add("show");
  }

  useEffect(() => {
    let milestone1 = document.querySelector("#Milestone1");
    let milestone2 = document.querySelector("#Milestone2");
    let milestone3 = document.querySelector("#Milestone3");
    let milestone4 = document.querySelector("#Milestone4");
    let milestone5 = document.querySelector("#Milestone5");
    let milestone6 = document.querySelector("#Milestone6");

    if (props.animate == true) {
      const controls = animate(0, totalCount, {
        duration: 2,
        ease: "easeOut",
        onComplete: (v) => {
          return showNum();
        },
        onUpdate(value) {
          setCount(value.toFixed(0));

          setReCount(calculatePercent(value));

          // Show milestones
          if (value >= 5000) {
            milestone1.classList.add("achived");
          }
          if (value >= 8000) {
            milestone2.classList.add("achived");
          }
          if (value >= 10000) {
            milestone3.classList.add("achived");
          }
          if (value >= 12500) {
            milestone4.classList.add("achived");
          }
          if (value >= 15000) {
            milestone5.classList.add("achived");
          }
          if (value >= 18000) {
            milestone6.classList.add("achived");
          }
        },
      });

      return () => controls.stop();
    }
  }, [props.animate]);

  useEffect(() => {
    // Set milestone range for modal
    if (totalCount >= 15000 && totalCount < 16000) {
      dispatch(setIsMilestone(true));
    } else if (totalCount >= 12500 && totalCount < 13500) {
      dispatch(setIsMilestone(true));
    } else if (totalCount >= 10000 && totalCount < 11000) {
      dispatch(setIsMilestone(true));
    } else if (totalCount >= 8000 && totalCount < 6000) {
      dispatch(setIsMilestone(true));
    } else if (totalCount >= 5000 && totalCount < 6000) {
      dispatch(setIsMilestone(true));
    }
  }, [totalCount]);

  return (
    <>
      <div className="counter-progress">
        {t("ProgressPlanted1")}&nbsp;
        <span>
          {count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}&nbsp;
        </span>
        {t("ProgressPlanted2")}
      </div>
      <ProgressSVG
        progressWidth={reCount}
        beePosition={{ transform: `translate(${reCount - 30}px) ` }}
        textPosition={count - 90}
        numDrags={totalCount}
      />
    </>
  );
};

export default Progress;
