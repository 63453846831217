import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { fadeInOut } from "../../utils/animations";
import BG from "../../assets/images/BG-static.png";

import imgMed from "../../assets/images/pages/med.jpg";
import imgMaticni from "../../assets/images/pages/maticni_mlecek.jpg";
import imgPropolis from "../../assets/images/pages/propolis.jpg";
import imgCvetni from "../../assets/images/pages/cvetni_prah.jpg";
import imgSirupi from "../../assets/images/pages/sirupi.jpg";
import imgKozmetika from "../../assets/images/pages/kozmetika.jpg";

const CebeljiIzdelki = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="pages pb-5"
      style={{ backgroundImage: `url(${BG})` }}>
      <div className="container-xl mt-6">
        <div className="wrapper variant-one rounded">
          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7">
              <h2 className="display-5 text-yellow">{t("PageIzdelkiP1H")}</h2>

              <p>{t("PageIzdelkiP1P1")}</p>
              <p>{t("PageIzdelkiP1P2")}</p>
              <div className="button-absolute">
                <a
                  className="btn btn-primary text-uppercase text-white px-5"
                  href={t("PageIzdelkiP1Link")}
                  target="_blank">
                  {t("PageIzdelkiButton")}
                </a>
              </div>
            </div>
            <div className="col-md-5">
              <img className="w-100" src={imgMed} />
            </div>
          </div>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7 order-md-2">
              <h2 className="display-5 text-yellow">{t("PageIzdelkiP2H")}</h2>

              <p>{t("PageIzdelkiP2P")}</p>

              <div className="button-absolute">
                <a
                  className="btn btn-primary text-uppercase text-white px-5"
                  href={t("PageIzdelkiP2Link")}
                  target="_blank">
                  {t("PageIzdelkiButton")}
                </a>
              </div>
            </div>
            <div className="col-md-5 order-md-1">
              <img className="w-100" src={imgMaticni} />
            </div>
          </div>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7">
              <h2 className="display-5 text-yellow">{t("PageIzdelkiP3H")}</h2>

              <p>{t("PageIzdelkiP3P")}</p>

              <div className="button-absolute">
                <a
                  className="btn btn-primary text-uppercase text-white px-5"
                  href={t("PageIzdelkiP3Link")}
                  target="_blank">
                  {t("PageIzdelkiButton")}
                </a>
              </div>
            </div>
            <div className="col-md-5">
              <img className="w-100" src={imgPropolis} />
            </div>
          </div>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7 order-md-2">
              <h2 className="display-5 text-yellow">{t("PageIzdelkiP4H")}</h2>

              <p>{t("PageIzdelkiP4P")}</p>

              <div className="button-absolute">
                <a
                  className="btn btn-primary text-uppercase text-white px-5"
                  href={t("PageIzdelkiP4Link")}
                  target="_blank">
                  {t("PageIzdelkiButton")}
                </a>
              </div>
            </div>
            <div className="col-md-5 order-md-1">
              <img className="w-100" src={imgCvetni} />
            </div>
          </div>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7">
              <h2 className="display-5 text-yellow">{t("PageIzdelkiP5H")}</h2>

              <p>{t("PageIzdelkiP5P")}</p>
              <div className="button-absolute">
                <a
                  className="btn btn-primary text-uppercase text-white px-5"
                  href={t("PageIzdelkiP5Link")}
                  target="_blank">
                  {t("PageIzdelkiButton")}
                </a>
              </div>
            </div>
            <div className="col-md-5">
              <img className="w-100" src={imgSirupi} />
            </div>
          </div>

          <div className="entry row g-3 g-sm-5 position-relative">
            <div className="col-md-7 order-md-2">
              <h2 className="display-5 text-yellow">{t("PageIzdelkiP6H")}</h2>

              <p>{t("PageIzdelkiP6P")}</p>

              <div className="button-absolute">
                <a
                  className="btn btn-primary text-uppercase text-white px-5"
                  href={t("PageIzdelkiP6Link")}
                  target="_blank">
                  {t("PageIzdelkiButton")}
                </a>
              </div>
            </div>
            <div className="col-md-5 order-md-1">
              <img className="w-100" src={imgKozmetika} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 text-center">
        <a
          className="btn btn-yellow text-uppercase text-white px-5 mt-3"
          href={"/?lang=" + currentLanguage}>
          {t("Back")}
        </a>
      </div>
    </motion.div>
  );
};

export default CebeljiIzdelki;
