import "./assets/styles/style.scss";
import "./i18n/config";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./utils/scrollToTop";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import Game from "./components/Game";
import CebeljiIzdelki from "./components/Pages/CebeljiIzdelki";
import OMedexu from "./components/Pages/OMedexu";
import PomagajCebelam from "./components/Pages/PomagajCebelam";
import OsupljivaDejstva from "./components/Pages/OsupljivaDejstva";
import Cookies from "./components/Pages/Cookies";
import Footer from "./components/Footer";

function App() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  useEffect(() => {
    /* 100vh mobile height fix */
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  return (
    <>
      <motion.main>
        <Helmet>
          <meta name="title" content={t("SocialTitle")} />
          <meta name="description" content={t("SocialDesc")} />
          <meta name="og:image" content={t("SocialImgLink")} />

          <meta name="og:type" content="website" />
          <meta name="og:url" content="https://help-a-bee.com/" />
          <meta name="og:title" content={t("SocialTitle")} />
          <meta name="og:description" content={t("SocialDesc")} />
          <meta name="og:image" content="" />

          {/*  */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:url" content="https://help-a-bee.com/" />
          <meta name="twitter:title" content={t("SocialTitle")} />
          <meta name="twitter:description" content={t("SocialDesc")} />
          <meta name="twitter:image" content="" />
        </Helmet>

        <ScrollToTop />
        <Header />
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Game />} />
            <Route path="cebelji-izdelki" element={<CebeljiIzdelki />} />
            <Route path="o-medexu" element={<OMedexu />} />
            <Route path="pomagaj-cebelam" element={<PomagajCebelam />} />
            <Route path="osupljiva-dejstva" element={<OsupljivaDejstva />} />
            <Route path="piskotki" element={<Cookies />} />
          </Routes>
        </AnimatePresence>
        <Footer currentLanguage={currentLanguage} />
      </motion.main>
    </>
  );
}

export default App;
