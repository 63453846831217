import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  treeNameReducer,
  setPlantedTrees,
  setNumPlantedToday,
  setModalOpenSuccess,
} from "../store/gameSlice";
import { useTranslation } from "react-i18next";
import { DropTarget } from "react-drag-drop-container";
import { useEffect, forwardRef } from "react";
import useSound from "use-sound";

import soundDrop from "../assets/sounds/drop.mp3";

/* const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() < today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
}; */

const Forest = forwardRef((props, refDrag) => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const userdata = useSelector((state) => state.game.userData);

  const numPlantedToday = useSelector((state) => state.game.numPlantedToday);

  const [play] = useSound(soundDrop, { volume: 0.3 });

  const data = {
    quizId: "ue7ad-idu6s5a-medexv2",
    guest: true,
    language: "sl",
    timezoneOffset: new Date().getTimezoneOffset(),
  };

  const dropped = (e) => {
    //e.containerElem.style.visibility = "hidden";

    // add inactive class if dragged
    //refDrag.current.classList.add("inactive");
    dispatch(treeNameReducer(e.dragData.label));

    window.scrollTo({ top: 0 });

    dispatch(setModalOpenSuccess(true));

    document.body.classList.add("modal-open");

    localStorage.setItem("time", new Date());
    play();
    // API call

    if (localStorage.getItem("token") === null) {
      axios
        .post("https://api.escapebox.si/quizbox/user", data)
        .then((res) => {
          localStorage.setItem("token", JSON.stringify(res.data.token));

          window.plausible("planted_trees", { props: { lang: i18n.language } });

          return axios.post(
            "https://api.escapebox.si/quizbox/plant-tree/ue7ad-idu6s5a-medexv2/plant",
            data,
            { headers: { Authorization: "Bearer " + res.data.token } }
          );
        })
        .then((res) => {
          dispatch(setPlantedTrees(res.data.planted_trees));
          dispatch(setNumPlantedToday(numPlantedToday + 1));
          //setData(res.data);
          //setIsOpen(false);
          //setLocalStorage();
          //setErrorMessage("");
        })

        .catch(function (error) {
          if (error.response) {
            //setValidated(false);
            //setErrorMessage(error.response.data.error_desc);
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }

          //setLoading(false);
        });
    } else {
      axios
        .post(
          "https://api.escapebox.si/quizbox/plant-tree/ue7ad-idu6s5a-medexv2/plant",
          { timezoneOffset: new Date().getTimezoneOffset() },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          window.plausible("planted_trees", { props: { lang: i18n.language } });
          dispatch(setPlantedTrees(res.data.planted_trees));
          dispatch(setNumPlantedToday(numPlantedToday + 1));
        })
        .catch(function (error) {
          if (error.response) {
            //setValidated(false);
            //setErrorMessage(error.response.data.error_desc);
            //console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }

          //setLoading(false);
        });
    }
  };

  /*   useEffect(() => {
    if (localStorage.getItem("countdown") === "true") {
      refDrag.current.classList.add("inactive");
    }
  }, [userdata]); */

  return (
    <>
      <DropTarget targetKey={props.targetKey} onHit={dropped}>
        {props.imageForest}
      </DropTarget>
    </>
  );
});

export default Forest;
